/** @jsx jsx */
import { useState } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import BackgroundImage from "gatsby-background-image";
import { Card, Heading, jsx, Text, Box } from "theme-ui";
import { Stack } from "raam";

const CaseStudyCard = ({ bg, children, excerpt, heading, image }) => {
  const cardContainer = {
    inactive: {
      y: 0,
      scale: 1,
    },
    hover: {
      y: -16,
      scale: 1.01,
      transition: {
        duration: 0.3,
      },
    },
  };

  const cardContent = {
    inactive: {
      y: 0,
    },
    hover: {
      y: 8,
      transition: {
        duration: 0.5,
      },
    },
  };

  const [isHover, setHover] = useState(false);

  const hoverHandler = () => {
    setHover((prevValue) => !prevValue);
  };

  return (
    <motion.div
      initial="inactive"
      sx={{ gridRow: "span 4" }}
      variants={cardContainer}
      whileHover="hover"
      onHoverEnd={hoverHandler}
      onHoverStart={hoverHandler}
    >
      <BackgroundImage
        fluid={image}
        sx={{ bg: `${bg}`, height: "full", position: "relative" }}
      >
        <Box
          bg={isHover && "blue.xdark"}
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            transition: "all 300ms ease-in-out",
            backgroundImage:
              "linear-gradient(180deg, rgb(58, 75, 121, 0.75) 0%, rgba(20,43,83,0) 75%)",
            opacity: isHover ? "0.75" : "1",
          }}
        />
        <Card sx={{ height: "full", position: "relative" }} variant="caseStudy">
          <motion.div variants={cardContent}>
            {isHover ? (
              <Text variant="body.lg">{children}</Text>
            ) : (
              <Stack gap="3">
                <Heading as="h5" sx={{ textShadow: "text" }} variant="title">
                  {heading}
                </Heading>
                <Text variant="description">{excerpt}</Text>
              </Stack>
            )}
          </motion.div>
        </Card>
      </BackgroundImage>
    </motion.div>
  );
};

CaseStudyCard.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.string,
  excerpt: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
    base64: PropTypes.string,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }),
};

CaseStudyCard.defaultProps = {
  bg: "",
  excerpt: "",
  heading: "",
  image: null,
};

export default CaseStudyCard;
