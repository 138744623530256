/* eslint-disable max-len */
/** @jsx jsx */
import { useContext } from "react";
import { jsx, useThemeUI } from "theme-ui";
import { motion } from "framer-motion";
import { IntersectionContext } from "../../hooks/intersection-observer";

const OmneTargetAnim = () => {
  const { theme } = useThemeUI();
  const { inView } = useContext(IntersectionContext);

  const computer = {
    initial: {
      opacity: 0,
      x: "-100%",
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 2,
        type: "spring",
        stiffness: 50,
      },
    },
  };

  const logoContainer = {
    initial: {
      scale: 0,
      opacity: 0,
    },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        delay: 0.25,
        duration: 2,
        type: "spring",
        stiffness: 50,
      },
    },
  };

  const target = {
    initial: {
      scale: 0,
      y: -56,
      opacity: 0,
    },
    animate: {
      scale: 1,
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.25,
        duration: 2,
        type: "spring",
        stiffness: 50,
      },
    },
  };

  const targetArrow = {
    initial: {
      x: 24,
      y: -24,
      scale: 0,
    },
    animate: {
      x: 0,
      y: 0,
      scale: 1,
      transition: {
        delay: 0.75,
      },
    },
  };

  return (
    <motion.svg
      animate={inView ? "animate" : "initial"}
      fill="none"
      initial="initial"
      sx={{
        width: "full",
        height: "full",
        transformOrigin: "center center !important",
      }}
      variants={computer}
      viewBox="0 0 850 583"
    >
      <path
        d="M425 582.67C659.721 582.67 850 567.895 850 549.67C850 531.445 659.721 516.67 425 516.67C190.279 516.67 0 531.445 0 549.67C0 567.895 190.279 582.67 425 582.67Z"
        fill={theme.colors.blue.dark}
      />
      <path
        d="M475.25 522.95L475.53 508.79C488.863 508.03 502.197 507.523 515.53 507.27C523.27 507.13 531.11 507.08 538.6 509.02C543.79 510.37 548.7 512.65 553.82 514.25C564.02 517.43 574.82 517.85 585.53 518.14C595.92 518.43 606.77 518.53 616.1 513.94C632.25 505.94 639.04 486.29 639.83 468.3C640.62 450.31 637.04 432.18 639.53 414.3"
        stroke="#3F3D56"
        strokeMiterlimit="10"
        strokeWidth="12"
      />
      <path
        d="M511.81 550.66L333.62 548.38L334.14 543.81L342.76 468.42H499.24L510.76 543.81L511.64 549.52L511.81 550.66Z"
        fill={theme.colors.grey.light}
      />
      <path
        d="M511.64 549.52H422.71L333.62 548.38L334.14 543.81H510.76L511.64 549.52Z"
        fill={theme.colors.grey.mid}
      />
      <path
        d="M540.37 544.95H303.92V550.66H540.37V544.95Z"
        fill={theme.colors.grey.light}
      />
      <path
        d="M765.96 15.6201C765.977 13.6623 765.607 11.7204 764.872 9.90584C764.137 8.09127 763.051 6.43976 761.676 5.04608C760.3 3.6524 758.664 2.54399 756.859 1.78447C755.055 1.02495 753.118 0.629259 751.16 0.620117H90.84C88.8825 0.630577 86.9463 1.02715 85.1424 1.78714C83.3385 2.54713 81.7022 3.65558 80.3274 5.049C78.9525 6.44241 77.8661 8.09342 77.1304 9.90739C76.3947 11.7214 76.0242 13.6627 76.04 15.6201V415.88H765.96V15.6201Z"
        fill="#3F3D56"
      />
      <path
        d="M76.04 411.88V458.77C76.04 462.695 77.5993 466.46 80.3748 469.235C83.1503 472.011 86.9148 473.57 90.84 473.57H751.16C755.085 473.57 758.85 472.011 761.625 469.235C764.401 466.46 765.96 462.695 765.96 458.77V411.88H76.04Z"
        fill={theme.colors.grey.light}
      />
      <path
        d="M740.83 25.8H104.6V385.61H740.83V25.8Z"
        fill={theme.colors.blue.xlight}
      />
      <path
        d="M439.85 473.56L510.94 544.95L500.03 473.56H439.85Z"
        fill={theme.colors.grey.mid}
      />
      <motion.g
        animate={inView ? "animate" : "initial"}
        initial="initial"
        variants={target}
      >
        <motion.path
          d="M663.889 215.742C657.739 215.742 652.766 210.814 652.766 204.719C652.766 199.733 656.15 195.563 660.741 194.193L670.334 184.687L669.951 183.578C668.009 183.024 666.008 182.645 663.889 182.645C651.589 182.645 641.642 192.531 641.642 204.69C641.642 216.85 651.618 226.735 663.889 226.735C676.19 226.735 686.136 216.85 686.136 204.69C686.136 202.591 685.754 200.608 685.195 198.683L684.076 198.333L674.483 207.84C673.129 212.505 668.804 215.742 663.889 215.742ZM704.999 191.831L698.819 197.954C697.878 198.887 696.73 199.587 695.494 200.025C698.26 217.462 686.225 233.792 668.657 236.533C651.059 239.274 634.58 227.348 631.814 209.939C629.048 192.501 641.083 176.172 658.651 173.431C661.977 172.906 665.39 172.906 668.715 173.431C669.157 172.206 669.834 171.069 670.746 170.106L676.926 163.983C672.717 162.671 668.333 162 663.919 162C640.083 162 620.779 181.129 620.779 204.719C620.779 228.31 640.083 247.439 663.889 247.439C687.696 247.439 707 228.31 707 204.719C707 200.345 706.323 196.001 704.999 191.831Z"
          fill="#5596CB"
        />
        <motion.path
          d="M674.66 174.014L685.724 163.05C687.137 161.679 689.373 161.679 690.786 163.05C691.168 163.429 691.463 163.896 691.639 164.42L694.876 174.014L704.558 177.222C706.441 177.834 707.442 179.846 706.794 181.712C706.618 182.237 706.323 182.704 705.941 183.083L694.876 194.076C693.934 195.009 692.522 195.359 691.227 194.951L682.576 192.093L666.832 207.694C665.214 209.298 662.565 209.298 660.917 207.694C659.299 206.09 659.299 203.466 660.917 201.833L676.661 186.232L673.777 177.659C673.365 176.347 673.718 174.947 674.66 174.014Z"
          fill="#3B4C7A"
          variants={targetArrow}
        />
      </motion.g>
      <motion.g
        animate={inView ? "animate" : "initial"}
        initial="initial"
        variants={logoContainer}
      >
        <path
          d="M401.223 180.254V232.451H393.925V180.254H375.18V173.372H419.939V180.283H401.223V180.254Z"
          fill="#3B4C7A"
        />
        <path
          d="M447.159 227.435C443.981 231.255 438.831 233.471 431.945 233.471C422.823 233.471 414.671 229.389 414.671 219.941C414.671 210.901 421.292 206.819 432.71 206.236C443.539 205.711 447.071 203.757 447.071 200.287C447.071 196.817 443.716 194.164 436.241 194.164C431.592 194.164 425.5 195.622 424.735 202.008H417.173C417.673 193.23 425 187.544 436.33 187.544C448.189 187.544 454.192 192.997 454.192 201.162V225.336C454.192 227.552 454.78 230.789 455.487 232.48H448.277C447.659 231.197 447.218 229.476 447.159 227.435ZM447.071 208.889C444.157 211.455 438.919 212.039 433.681 212.389C424.059 212.972 421.91 216.296 421.91 219.97C421.91 224.752 426.56 226.794 432.21 226.794C439.773 226.794 446.894 223.557 447.071 215.625V208.889Z"
          fill="#3B4C7A"
        />
        <path
          d="M484.502 194.747C476.851 194.309 470.318 198.333 470.318 207.081V232.451H463.049V188.623H470.083V193.551C473.437 188.856 479.44 186.99 484.531 187.603V194.747H484.502Z"
          fill="#3B4C7A"
        />
        <path
          d="M529.231 230.409C529.231 244.465 520.05 254 506.454 254C495.978 254 487.798 248.46 485.591 239.537H493.242C496.155 246.098 503.217 247.031 506.484 247.031C517.813 247.031 521.962 238.341 521.962 230.439V225.831C518.784 230.089 513.281 233.588 506.925 233.588C494.213 233.588 484.767 223.294 484.767 210.61C484.767 197.925 494.213 187.544 506.925 187.544C513.458 187.544 519.137 191.218 522.227 195.622V188.623H529.26V230.409H529.231ZM506.896 194.222C498.568 194.222 492.035 201.541 492.035 210.668C492.035 219.62 498.568 226.939 506.896 226.939C515.047 226.939 521.933 219.62 521.933 210.668C521.933 201.541 515.077 194.222 506.896 194.222Z"
          fill="#3B4C7A"
        />
        <path
          d="M578.61 218.833C575.52 228.193 567.957 233.471 557.746 233.471C545.033 233.471 535.058 223.411 535.058 210.581C535.058 197.896 545.033 187.515 557.304 187.515C568.722 187.515 579.728 196.875 579.639 212.476H542.444C543.385 221.574 550.448 226.939 557.746 226.939C564.014 226.939 568.487 223.965 570.9 218.862H578.61V218.833ZM571.577 205.973C569.781 198.829 563.749 194.047 557.304 194.047C551.213 194.047 544.768 198.479 542.856 205.973H571.577Z"
          fill="#3B4C7A"
        />
        <path
          d="M603.24 232.451C592.941 233.646 585.79 227.61 585.79 216.442V195.505H579.787V188.623H585.79V179.233H592.999V188.594H603.064V195.505H592.999V216.442C592.999 224.198 597.826 226.152 603.211 225.802V232.451H603.24Z"
          fill="#3B4C7A"
        />
        <path
          d="M201.119 202.649C201.119 219.854 187.877 233.471 171.574 233.471C155.272 233.471 142 219.941 142 202.649C142 185.474 155.242 171.915 171.545 171.915C188.142 171.915 201.119 185.124 201.119 202.649ZM193.909 202.649C193.909 189.032 184.11 178.796 171.574 178.796C159.274 178.796 149.239 189.265 149.239 202.649C149.239 216.092 159.303 226.502 171.574 226.502C183.845 226.502 193.909 216.034 193.909 202.649Z"
          fill="#DD242F"
        />
        <path
          d="M270.949 232.451H263.652V205.128C263.652 198.304 260.385 194.135 253.587 194.135C247.584 194.135 243.2 199.063 243.2 205.624V232.451H235.99V205.128C235.99 198.304 232.547 194.135 226.014 194.135C220.011 194.135 215.538 199.063 215.538 205.624V232.451H208.24V188.623H215.273V193.406C218.01 189.731 222.159 187.544 227.044 187.544C233.312 187.544 237.962 190.344 240.698 195.039C243.435 190.519 248.173 187.544 254.529 187.544C264.77 187.544 270.949 194.426 270.949 205.332V232.451Z"
          fill="#DD242F"
        />
        <path
          d="M316.797 232.451H309.587V205.799C309.587 199.063 305.791 194.135 298.758 194.135C292.049 194.135 287.164 199.063 287.164 205.624V232.451H279.866V188.623H286.899V193.406C289.901 189.731 294.462 187.544 299.788 187.544C310.617 187.544 316.797 195.214 316.797 206.119V232.451Z"
          fill="#DD242F"
        />
        <path
          d="M366.087 218.833C362.997 228.193 355.435 233.471 345.223 233.471C332.511 233.471 322.535 223.411 322.535 210.581C322.535 197.896 332.511 187.515 344.782 187.515C356.2 187.515 367.205 196.876 367.117 212.476H329.921C330.863 221.574 337.926 226.94 345.223 226.94C351.491 226.94 355.964 223.965 358.377 218.862H366.087V218.833ZM359.054 205.973C357.259 198.829 351.227 194.047 344.782 194.047C338.691 194.047 332.246 198.479 330.333 205.973H359.054Z"
          fill="#DD242F"
        />
      </motion.g>
    </motion.svg>
  );
};

export default OmneTargetAnim;
