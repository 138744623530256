/** @jsx jsx */
import { jsx } from "theme-ui";
// eslint-disable-next-line no-unused-vars
import React, { createContext, useEffect, useRef, useState } from "react";
import { useIntersection } from "react-use";
import PropTypes from "prop-types";

export const IntersectionContext = createContext({ inView: true });

export const IntersectionObserver = ({
  children,
  className,
  reset = false, // if value set to true - observed element will reappear every time it shows up on the screen
  sx,
}) => {
  const [inView, setInView] = useState(false);
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    threshold: 0.75,
  });

  useEffect(() => {
    const inViewNow = intersection && intersection.intersectionRatio > 0;
    if (inViewNow) {
      return setInView(inViewNow);
    }
    if (reset) {
      return setInView(false);
    }
    return undefined;
  }, [intersection, reset]);

  return (
    <IntersectionContext.Provider value={{ inView }}>
      <div ref={intersectionRef} className={className} sx={sx}>
        {children}
      </div>
    </IntersectionContext.Provider>
  );
};

IntersectionObserver.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  reset: PropTypes.bool,
  sx: PropTypes.shape({}),
};

IntersectionObserver.defaultProps = {
  className: null,
  reset: false,
  sx: null,
};
