/* eslint-disable max-len */
/** @jsx jsx */
import { useContext } from "react";
import { jsx, useThemeUI } from "theme-ui";
import { motion } from "framer-motion";
import { IntersectionContext } from "../../hooks/intersection-observer";

const OmneNativeAnim = () => {
  const { theme } = useThemeUI();
  const { inView } = useContext(IntersectionContext);

  const wrapper = {
    initial: {
      opacity: 0,
      x: "100%",
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        type: "spring",
        stiffness: 50,
      },
    },
  };

  const homePage = {
    initial: {
      opacity: 1,
      scale: 1,
    },
    animate: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 0.5,
        type: "spring",
        stiffness: "100",
        when: "afterChildren",
      },
    },
  };

  const items = {
    initial: {
      scale: 0,
      opacity: 0,
      y: 32,
    },
    animate: {
      scale: 1,
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.05,
        type: "spring",
        stiffness: "50",
      },
    },
  };

  const omneLogo = {
    initial: {
      scale: 0,
      opacity: 0,
      y: 32,
    },
    animate: {
      scale: 1,
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.1,
        type: "spring",
        stiffness: "50",
      },
    },
  };

  const articlePage = {
    initial: {
      opacity: 0,
      scale: 0,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 1,
        duration: 1,
        type: "spring",
        stiffness: "50",
        when: "beforeChildren",
        staggerChildren: "0.05",
        staggerDirection: -1,
      },
    },
  };

  return (
    <motion.svg
      animate={inView ? "animate" : "initial"}
      fill="none"
      initial="initial"
      sx={{
        width: "full",
        height: "full",
        transformOrigin: "center center !important",
      }}
      variants={wrapper}
      viewBox="70 152 948 535"
    >
      <motion.g clipPath="url(#clip0)">
        <path
          d="M82.5079 152H1005.49C1012.4 152 1018 157.664 1018 164.642V674.358C1018 681.336 1012.4 687 1005.49 687H82.5079C75.6035 687 70 681.336 70 674.358V164.642C70 157.562 75.6035 152 82.5079 152Z"
          fill={theme.colors.grey.xlight}
        />
        <path
          d="M144.592 205H943.408C949.203 205 954 210.422 954 217.023V655.977C954 662.578 949.303 668 943.408 668H144.592C138.796 668 134 662.578 134 655.977V217.023C134 210.422 138.697 205 144.592 205Z"
          fill={theme.colors.white}
        />
        <motion.g
          animate={inView ? "animate" : "initial"}
          id="articleLayout"
          initial="initial"
          sx={{ transformOrigin: "center center !important" }}
          variants={articlePage}
        >
          <motion.path
            d="M193.9 615H893.2C895.3 615 897.1 616.7 897.1 618.9V635C897.1 637.1 895.4 638.9 893.2 638.9H193.9C191.8 638.9 190 637.2 190 635V618.9C190.1 616.7 191.8 615 193.9 615Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M193.9 576H893.2C895.3 576 897.1 577.7 897.1 579.9V596C897.1 598.1 895.4 599.9 893.2 599.9H193.9C191.8 599.9 190 598.2 190 596V579.9C190.1 577.7 191.8 576 193.9 576Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M193.9 537H893.2C895.3 537 897.1 538.7 897.1 540.9V557C897.1 559.1 895.4 560.9 893.2 560.9H193.9C191.8 560.9 190 559.2 190 557V540.9C190.1 538.7 191.8 537 193.9 537Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M518.22 498H893.78C896.052 498 898 499.707 898 501.916V518.084C898 520.192 896.161 522 893.78 522H518.22C515.948 522 514 520.293 514 518.084V501.916C514.108 499.707 515.948 498 518.22 498Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M518.22 459H893.78C896.052 459 898 460.707 898 462.916V479.084C898 481.192 896.161 483 893.78 483H518.22C515.948 483 514 481.293 514 479.084V462.916C514.108 460.707 515.948 459 518.22 459Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M518.22 420H893.78C896.052 420 898 421.707 898 423.916V440.084C898 442.192 896.161 444 893.78 444H518.22C515.948 444 514 442.293 514 440.084V423.916C514.108 421.707 515.948 420 518.22 420Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M518.22 381H893.78C896.052 381 898 382.707 898 384.916V401.084C898 403.192 896.161 405 893.78 405H518.22C515.948 405 514 403.293 514 401.084V384.916C514.108 382.707 515.948 381 518.22 381Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M518.22 342H893.78C896.052 342 898 343.707 898 345.916V362.084C898 364.192 896.161 366 893.78 366H518.22C515.948 366 514 364.293 514 362.084V345.916C514.108 343.707 515.948 342 518.22 342Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M518.22 303H893.78C896.052 303 898 304.707 898 306.916V323.084C898 325.192 896.161 327 893.78 327H518.22C515.948 327 514 325.293 514 323.084V306.916C514.108 304.707 515.948 303 518.22 303Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M199.5 238H484.5C489.2 238 493 242.872 493 248.898V511.102C493 517.128 489.2 522 484.5 522H199.5C194.8 522 191 517.128 191 511.102V248.898C191 242.872 194.8 238 199.5 238Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.g id="omneNativeLogo" variants={omneLogo}>
            <path
              d="M898 253.219V270.098C898 270.869 897.36 271.501 896.58 271.501H887.88C887.64 270.197 886.94 269.011 885.9 268.161L872.94 257.765L875.26 255.67C875.82 255.136 875.84 254.247 875.3 253.673C874.78 253.12 873.9 253.081 873.34 253.594L870.94 255.769L866.22 260.038C866.22 260.038 866.2 260.038 866.18 260.057C864.3 261.718 862.04 260.749 861.18 259.82C859.86 258.397 859.94 256.203 861.36 254.899C861.36 254.899 861.38 254.879 861.38 254.859L870.14 246.934C870.66 246.459 871.36 246.202 872.06 246.202H879.72C880.48 246.202 881.2 246.499 881.74 247.033L886.6 251.835H896.58C897.36 251.835 898 252.448 898 253.219Z"
              fill="#3B4C7A"
            />
            <path
              d="M841 270.137V253.239C841 252.468 841.64 251.835 842.42 251.835H852.4L857.28 247.033C857.82 246.499 858.54 246.202 859.3 246.202H866.76L859.48 252.804C856.88 255.176 856.7 259.168 859.08 261.757C860.4 263.2 864.52 265.414 868.14 262.153L870.82 259.741L884.12 270.414C885.34 271.402 885.52 273.181 884.52 274.387L883.68 275.415C882.68 276.62 880.9 276.798 879.68 275.83L879.2 275.434L876.4 278.834C875.24 280.237 873.16 280.455 871.74 279.308L870.14 277.945L870.12 277.964C868.14 280.376 864.54 280.751 862.1 278.775L854.04 271.561H842.44C841.64 271.541 841 270.908 841 270.137Z"
              fill="#5596CB"
            />
            <path
              d="M709.56 277.925H704.22L683.02 246.202V277.925H678.18V238.949H683.58L704.78 270.612V238.949H709.56V277.925V277.925Z"
              fill="#3B4C7A"
            />
            <path
              d="M735.88 274.604C733.78 277.134 730.36 278.597 725.82 278.597C719.8 278.597 714.4 275.909 714.4 269.663C714.4 263.714 718.78 261.006 726.34 260.63C733.5 260.294 735.82 259.01 735.82 256.697C735.82 254.385 733.6 252.645 728.66 252.645C725.6 252.645 721.56 253.594 721.04 257.804H716.04C716.38 252.013 721.22 248.258 728.72 248.258C736.56 248.258 740.54 251.855 740.54 257.251V273.201C740.54 274.663 740.94 276.798 741.4 277.925H736.62C736.22 277.094 735.94 275.968 735.88 274.604ZM735.82 262.37C733.88 264.05 730.42 264.445 726.96 264.662C720.6 265.058 719.18 267.252 719.18 269.663C719.18 272.805 722.24 274.149 726 274.149C731 274.149 735.72 272.015 735.84 266.797V262.37H735.82Z"
              fill="#3B4C7A"
            />
            <path
              d="M758.52 277.925C751.7 278.715 746.98 274.723 746.98 267.37V253.555H743V249.009H746.98V242.823H751.76V249.009H758.4V253.555H751.76V267.37C751.76 272.489 754.94 273.774 758.52 273.537V277.925V277.925Z"
              fill="#3B4C7A"
            />
            <path
              d="M766.6 244.621H761.76V238.949H766.6V244.621ZM766.6 277.925H761.76V249.009H766.6V277.925Z"
              fill="#3B4C7A"
            />
            <path
              d="M784.9 277.925H779.72L769.1 249.009H774.22L782.34 272.035L790.42 249.009H795.54L784.9 277.925Z"
              fill="#3B4C7A"
            />
            <path
              d="M823.82 268.932C821.78 275.118 816.78 278.597 810.02 278.597C801.6 278.597 795.02 271.975 795.02 263.496C795.02 255.136 801.62 248.278 809.74 248.278C817.3 248.278 824.58 254.464 824.52 264.722H799.92C800.54 270.73 805.2 274.268 810.04 274.268C814.18 274.268 817.14 272.311 818.74 268.932H823.82ZM819.18 260.453C817.98 255.729 814 252.586 809.74 252.586C805.7 252.586 801.44 255.511 800.2 260.453H819.18Z"
              fill="#3B4C7A"
            />
            <path
              d="M553.1 258.279C553.1 269.624 544.34 278.616 533.56 278.616C522.78 278.616 514 269.663 514 258.279C514 246.934 522.76 238 533.54 238C544.52 238 553.1 246.696 553.1 258.279ZM548.32 258.279C548.32 249.286 541.84 242.546 533.54 242.546C525.42 242.546 518.78 249.464 518.78 258.279C518.78 267.153 525.42 274.011 533.56 274.011C541.7 274.011 548.32 267.094 548.32 258.279Z"
              fill="#DD242F"
            />
            <path
              d="M599.28 277.925H594.44V259.899C594.44 255.413 592.28 252.646 587.8 252.646C583.82 252.646 580.92 255.907 580.92 260.235V277.925H576.14V259.899C576.14 255.413 573.86 252.646 569.54 252.646C565.56 252.646 562.6 255.907 562.6 260.235V277.925H557.8V249.009H562.46V252.151C564.28 249.74 567 248.278 570.24 248.278C574.38 248.278 577.46 250.135 579.28 253.219C581.1 250.234 584.22 248.278 588.42 248.278C595.18 248.278 599.28 252.823 599.28 260.018V277.925Z"
              fill="#DD242F"
            />
            <path
              d="M629.6 277.925H624.82V260.354C624.82 255.927 622.32 252.665 617.66 252.665C613.22 252.665 609.98 255.927 609.98 260.255V277.925H605.16V249.009H609.82V252.151C611.8 249.74 614.82 248.278 618.34 248.278C625.5 248.278 629.6 253.337 629.6 260.512V277.925Z"
              fill="#DD242F"
            />
            <path
              d="M662.2 268.932C660.16 275.118 655.16 278.597 648.4 278.597C639.98 278.597 633.4 271.975 633.4 263.496C633.4 255.136 640 248.278 648.12 248.278C655.68 248.278 662.96 254.464 662.9 264.722H638.3C638.92 270.73 643.58 274.268 648.42 274.268C652.56 274.268 655.52 272.311 657.12 268.932H662.2ZM657.54 260.453C656.34 255.729 652.36 252.586 648.1 252.586C644.06 252.586 639.8 255.511 638.56 260.453H657.54Z"
              fill="#DD242F"
            />
          </motion.g>
        </motion.g>
        <motion.g
          animate={inView ? "animate" : "initial"}
          id="homePage"
          initial="initial"
          sx={{ transformOrigin: "center center !important" }}
          variants={homePage}
        >
          <motion.path
            d="M198.575 258H318.425C320.402 258 322 260.042 322 262.567V372.433C322 374.958 320.402 377 318.425 377H198.575C196.598 377 195 374.958 195 372.433V262.567C195 260.042 196.598 258 198.575 258Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M195.524 385H289.476C289.758 385 290 385.569 290 386.305V391.695C290 392.397 289.772 393 289.476 393H195.524C195.242 393 195 392.431 195 391.695V386.305C195.013 385.569 195.242 385 195.524 385Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M195.7 401H321.3C321.677 401 322 401.569 322 402.305V407.695C322 408.397 321.695 409 321.3 409H195.7C195.323 409 195 408.431 195 407.695V402.305C195.018 401.569 195.323 401 195.7 401Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M195.629 417H308.371C308.71 417 309 417.569 309 418.305V423.695C309 424.397 308.726 425 308.371 425H195.629C195.29 425 195 424.431 195 423.695V418.305C195.016 417.569 195.29 417 195.629 417Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M341.575 258H461.425C463.402 258 465 260.042 465 262.567V372.433C465 374.958 463.402 377 461.425 377H341.575C339.598 377 338 374.958 338 372.433V262.567C338 260.042 339.598 258 341.575 258Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M338.524 385H432.476C432.758 385 433 385.569 433 386.305V391.695C433 392.397 432.772 393 432.476 393H338.524C338.242 393 338 392.431 338 391.695V386.305C338.013 385.569 338.242 385 338.524 385Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M338.7 401H464.3C464.677 401 465 401.569 465 402.305V407.695C465 408.397 464.695 409 464.3 409H338.7C338.323 409 338 408.431 338 407.695V402.305C338.018 401.569 338.323 401 338.7 401Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M338.629 417H451.371C451.71 417 452 417.569 452 418.305V423.695C452 424.397 451.726 425 451.371 425H338.629C338.29 425 338 424.431 338 423.695V418.305C338.016 417.569 338.29 417 338.629 417Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M484.575 258H604.425C606.402 258 608 260.042 608 262.567V372.433C608 374.958 606.402 377 604.425 377H484.575C482.598 377 481 374.958 481 372.433V262.567C481 260.042 482.598 258 484.575 258Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M481.524 385H575.476C575.758 385 576 385.569 576 386.305V391.695C576 392.397 575.772 393 575.476 393H481.524C481.242 393 481 392.431 481 391.695V386.305C481.013 385.569 481.242 385 481.524 385Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M481.7 401H607.3C607.677 401 608 401.569 608 402.305V407.695C608 408.397 607.695 409 607.3 409H481.7C481.323 409 481 408.431 481 407.695V402.305C481.018 401.569 481.323 401 481.7 401Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M481.629 417H594.371C594.71 417 595 417.569 595 418.305V423.695C595 424.397 594.726 425 594.371 425H481.629C481.29 425 481 424.431 481 423.695V418.305C481.016 417.569 481.29 417 481.629 417Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M627.575 258H747.425C749.402 258 751 260.042 751 262.567V372.433C751 374.958 749.402 377 747.425 377H627.575C625.598 377 624 374.958 624 372.433V262.567C624 260.042 625.598 258 627.575 258Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M624.524 385H718.476C718.758 385 719 385.569 719 386.305V391.695C719 392.397 718.772 393 718.476 393H624.524C624.242 393 624 392.431 624 391.695V386.305C624.013 385.569 624.242 385 624.524 385Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M624.7 401H750.3C750.677 401 751 401.569 751 402.305V407.695C751 408.397 750.695 409 750.3 409H624.7C624.323 409 624 408.431 624 407.695V402.305C624.018 401.569 624.323 401 624.7 401Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M624.629 417H737.371C737.71 417 738 417.569 738 418.305V423.695C738 424.397 737.726 425 737.371 425H624.629C624.29 425 624 424.431 624 423.695V418.305C624.016 417.569 624.29 417 624.629 417Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M770.575 258H890.425C892.402 258 894 260.042 894 262.567V372.433C894 374.958 892.402 377 890.425 377H770.575C768.598 377 767 374.958 767 372.433V262.567C767 260.042 768.598 258 770.575 258Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M767.524 385H861.476C861.758 385 862 385.569 862 386.305V391.695C862 392.397 861.772 393 861.476 393H767.524C767.242 393 767 392.431 767 391.695V386.305C767.013 385.569 767.242 385 767.524 385Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M767.7 401H893.3C893.677 401 894 401.569 894 402.305V407.695C894 408.397 893.695 409 893.3 409H767.7C767.323 409 767 408.431 767 407.695V402.305C767.018 401.569 767.323 401 767.7 401Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M767.629 417H880.371C880.71 417 881 417.569 881 418.305V423.695C881 424.397 880.726 425 880.371 425H767.629C767.29 425 767 424.431 767 423.695V418.305C767.016 417.569 767.29 417 767.629 417Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M198.575 449H318.425C320.402 449 322 451.042 322 453.567V563.433C322 565.958 320.402 568 318.425 568H198.575C196.598 568 195 565.958 195 563.433V453.567C195 451.042 196.598 449 198.575 449Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M195.524 576H289.476C289.758 576 290 576.569 290 577.305V582.695C290 583.397 289.772 584 289.476 584H195.524C195.242 584 195 583.431 195 582.695V577.305C195.013 576.569 195.242 576 195.524 576Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M195.7 592H321.3C321.677 592 322 592.569 322 593.305V598.695C322 599.397 321.695 600 321.3 600H195.7C195.323 600 195 599.431 195 598.695V593.305C195.018 592.569 195.323 592 195.7 592Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M195.629 608H308.371C308.71 608 309 608.569 309 609.305V614.695C309 615.397 308.726 616 308.371 616H195.629C195.29 616 195 615.431 195 614.695V609.305C195.016 608.569 195.29 608 195.629 608Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M341.575 449H461.425C463.402 449 465 451.042 465 453.567V563.433C465 565.958 463.402 568 461.425 568H341.575C339.598 568 338 565.958 338 563.433V453.567C338 451.042 339.598 449 341.575 449Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M338.524 576H432.476C432.758 576 433 576.569 433 577.305V582.695C433 583.397 432.772 584 432.476 584H338.524C338.242 584 338 583.431 338 582.695V577.305C338.013 576.569 338.242 576 338.524 576Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M338.7 592H464.3C464.677 592 465 592.569 465 593.305V598.695C465 599.397 464.695 600 464.3 600H338.7C338.323 600 338 599.431 338 598.695V593.305C338.018 592.569 338.323 592 338.7 592Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M338.629 608H451.371C451.71 608 452 608.569 452 609.305V614.695C452 615.397 451.726 616 451.371 616H338.629C338.29 616 338 615.431 338 614.695V609.305C338.016 608.569 338.29 608 338.629 608Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M484.575 449H604.425C606.402 449 608 451.042 608 453.567V563.433C608 565.958 606.402 568 604.425 568H484.575C482.598 568 481 565.958 481 563.433V453.567C481 451.042 482.598 449 484.575 449Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M481.524 576H575.476C575.758 576 576 576.569 576 577.305V582.695C576 583.397 575.772 584 575.476 584H481.524C481.242 584 481 583.431 481 582.695V577.305C481.013 576.569 481.242 576 481.524 576Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M481.7 592H607.3C607.677 592 608 592.569 608 593.305V598.695C608 599.397 607.695 600 607.3 600H481.7C481.323 600 481 599.431 481 598.695V593.305C481.018 592.569 481.323 592 481.7 592Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M481.629 608H594.371C594.71 608 595 608.569 595 609.305V614.695C595 615.397 594.726 616 594.371 616H481.629C481.29 616 481 615.431 481 614.695V609.305C481.016 608.569 481.29 608 481.629 608Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M627.575 449H747.425C749.402 449 751 451.042 751 453.567V563.433C751 565.958 749.402 568 747.425 568H627.575C625.598 568 624 565.958 624 563.433V453.567C624 451.042 625.598 449 627.575 449Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M624.524 576H718.476C718.758 576 719 576.569 719 577.305V582.695C719 583.397 718.772 584 718.476 584H624.524C624.242 584 624 583.431 624 582.695V577.305C624.013 576.569 624.242 576 624.524 576Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M624.7 592H750.3C750.677 592 751 592.569 751 593.305V598.695C751 599.397 750.695 600 750.3 600H624.7C624.323 600 624 599.431 624 598.695V593.305C624.018 592.569 624.323 592 624.7 592Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M624.629 608H737.371C737.71 608 738 608.569 738 609.305V614.695C738 615.397 737.726 616 737.371 616H624.629C624.29 616 624 615.431 624 614.695V609.305C624.016 608.569 624.29 608 624.629 608Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M770.575 449H890.425C892.402 449 894 451.042 894 453.567V563.433C894 565.958 892.402 568 890.425 568H770.575C768.598 568 767 565.958 767 563.433V453.567C767 451.042 768.598 449 770.575 449Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M767.524 576H861.476C861.758 576 862 576.569 862 577.305V582.695C862 583.397 861.772 584 861.476 584H767.524C767.242 584 767 583.431 767 582.695V577.305C767.013 576.569 767.242 576 767.524 576Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M767.7 592H893.3C893.677 592 894 592.569 894 593.305V598.695C894 599.397 893.695 600 893.3 600H767.7C767.323 600 767 599.431 767 598.695V593.305C767.018 592.569 767.323 592 767.7 592Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
          <motion.path
            d="M767.629 608H880.371C880.71 608 881 608.569 881 609.305V614.695C881 615.397 880.726 616 880.371 616H767.629C767.29 616 767 615.431 767 614.695V609.305C767.016 608.569 767.29 608 767.629 608Z"
            fill={theme.colors.grey.light}
            variants={items}
          />
        </motion.g>
        <path
          d="M1013.29 151.7H74.803C72.1013 151.7 70 153.891 70 156.481V176.7H1018V156.481C1018.1 153.891 1015.9 151.7 1013.29 151.7Z"
          fill="#5A5773"
        />
        <path
          d="M124.3 170.8C127.89 170.8 130.8 167.89 130.8 164.3C130.8 160.71 127.89 157.8 124.3 157.8C120.71 157.8 117.8 160.71 117.8 164.3C117.8 167.89 120.71 170.8 124.3 170.8Z"
          fill="white"
        />
        <path
          d="M106.8 170.8C110.39 170.8 113.3 167.89 113.3 164.3C113.3 160.71 110.39 157.8 106.8 157.8C103.21 157.8 100.3 160.71 100.3 164.3C100.3 167.89 103.21 170.8 106.8 170.8Z"
          fill="white"
        />
        <path
          d="M89.3 170.8C92.8898 170.8 95.8 167.89 95.8 164.3C95.8 160.71 92.8898 157.8 89.3 157.8C85.7101 157.8 82.8 160.71 82.8 164.3C82.8 167.89 85.7101 170.8 89.3 170.8Z"
          fill="white"
        />
      </motion.g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="745.7" width="1135.7" />
        </clipPath>
        <clipPath id="clip1">
          <rect fill="white" height="42" width="384" x="514" y="238" />
        </clipPath>
      </defs>
    </motion.svg>
  );
};

export default OmneNativeAnim;
