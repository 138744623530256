/* eslint-disable max-len */
/** @jsx jsx */
import { useContext } from "react";
import { jsx, useThemeUI } from "theme-ui";
import { motion } from "framer-motion";
import { IntersectionContext } from "../../hooks/intersection-observer";

const OmneSearchAnim = () => {
  const { theme } = useThemeUI();
  const { inView } = useContext(IntersectionContext);

  const wrapper = {
    initial: {
      opacity: 0,
      x: "100%",
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.1,
        type: "spring",
        stiffness: 50,
        when: "beforeChildren",
        staggerChildren: 3,
      },
    },
  };

  const logoContainerAnim = {
    initial: {
      scale: 0,
    },
    animate: {
      scale: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        stiffness: 50,
      },
    },
  };

  const logoAnim = {
    initial: {
      scale: 0,
    },
    animate: {
      scale: 1,
      transition: {
        duration: 0.25,
        type: "spring",
        stiffness: 50,
      },
    },
  };

  const pageContent = {
    initial: {},
    animate: {
      transition: {
        delay: 2,
        duration: 0.5,
        type: "spring",
        stiffness: 50,
        when: "beforeChildren",
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  };

  const pageItem = {
    initial: {
      opacity: 0,
      y: -8,
    },
    animate: {
      opacity: 0.2,
      y: 0,
      transition: {
        duration: 0.1,
        type: "spring",
        stiffness: "50",
      },
    },
  };

  return (
    <motion.svg
      animate={inView ? "animate" : "initial"}
      fill="none"
      initial="initial"
      sx={{
        width: "full",
        height: "full",
        transformOrigin: "center center !important",
      }}
      variants={wrapper}
      viewBox="70 152 948 535"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M82.5079 152H1005.49C1012.4 152 1018 157.664 1018 164.642V674.358C1018 681.336 1012.4 687 1005.49 687H82.5079C75.6035 687 70 681.336 70 674.358V164.642C70 157.562 75.6035 152 82.5079 152Z"
          fill={theme.colors.grey.xlight}
        />
        <path
          d="M144.4 275.3H943.8C949.6 275.3 954.4 279.9 954.4 285.5V657.9C954.4 663.5 949.7 668.1 943.8 668.1H144.4C138.6 668.1 133.8 663.5 133.8 657.9V285.5C133.8 279.9 138.5 275.3 144.4 275.3Z"
          fill="white"
        />
        <g>
          <motion.g
            animate={inView ? "animate" : "initial"}
            initial="initial"
            variants={pageContent}
          >
            <motion.path
              d="M193.9 615H893.2C895.3 615 897.1 616.7 897.1 618.9V635C897.1 637.1 895.4 638.9 893.2 638.9H193.9C191.8 638.9 190 637.2 190 635V618.9C190.1 616.7 191.8 615 193.9 615Z"
              fill={theme.colors.grey.dark}
              opacity="0.2"
              variants={pageItem}
            />
            <motion.path
              d="M193.9 576H893.2C895.3 576 897.1 577.7 897.1 579.9V596C897.1 598.1 895.4 599.9 893.2 599.9H193.9C191.8 599.9 190 598.2 190 596V579.9C190.1 577.7 191.8 576 193.9 576Z"
              fill={theme.colors.grey.dark}
              opacity="0.2"
              variants={pageItem}
            />
            <motion.path
              d="M193.9 537H893.2C895.3 537 897.1 538.7 897.1 540.9V557C897.1 559.1 895.4 560.9 893.2 560.9H193.9C191.8 560.9 190 559.2 190 557V540.9C190.1 538.7 191.8 537 193.9 537Z"
              fill={theme.colors.grey.dark}
              opacity="0.2"
              variants={pageItem}
            />
            <motion.path
              d="M546.9 498H894C896.1 498 897.9 499.7 897.9 501.9V518C897.9 520.1 896.2 521.9 894 521.9H546.9C544.8 521.9 543 520.2 543 518V501.9C543.1 499.7 544.8 498 546.9 498Z"
              fill={theme.colors.grey.dark}
              opacity="0.2"
              variants={pageItem}
            />
            <motion.path
              d="M546.9 459H894C896.1 459 897.9 460.7 897.9 462.9V479C897.9 481.1 896.2 482.9 894 482.9H546.9C544.8 482.9 543 481.2 543 479V462.9C543.1 460.7 544.8 459 546.9 459Z"
              fill={theme.colors.grey.dark}
              opacity="0.2"
              variants={pageItem}
            />
            <motion.path
              d="M546.9 420H894C896.1 420 897.9 421.7 897.9 423.9V440C897.9 442.1 896.2 443.9 894 443.9H546.9C544.8 443.9 543 442.2 543 440V423.9C543.1 421.7 544.8 420 546.9 420Z"
              fill={theme.colors.grey.dark}
              opacity="0.2"
              variants={pageItem}
            />
            <motion.path
              d="M546.9 380.8H894C896.1 380.8 897.9 382.5 897.9 384.7V400.8C897.9 402.9 896.2 404.7 894 404.7H546.9C544.8 404.7 543 403 543 400.8V384.7C543.1 382.5 544.8 380.8 546.9 380.8Z"
              fill={theme.colors.grey.dark}
              opacity="0.2"
              variants={pageItem}
            />
            <motion.path
              d="M546.6 314H766.1C768.2 314 770 315.7 770 317.9V334C770 336.1 768.3 337.9 766.1 337.9H546.6C544.5 337.9 542.7 336.2 542.7 334V317.9C542.8 315.8 544.5 314 546.6 314Z"
              fill={theme.colors.grey.dark}
              opacity="0.2"
              variants={pageItem}
            />
            <motion.path
              d="M199.1 300.8H484.1C488.8 300.8 492.6 304.6 492.6 309.3V513.8C492.6 518.5 488.8 522.3 484.1 522.3H199.1C194.4 522.3 190.6 518.5 190.6 513.8V309.3C190.6 304.6 194.4 300.8 199.1 300.8Z"
              fill={theme.colors.grey.dark}
              opacity="0.2"
              variants={pageItem}
            />
          </motion.g>
        </g>
        <path
          d="M1013.29 151.7H74.803C72.1013 151.7 70 153.891 70 156.481V176.7H1018V156.481C1018.1 153.891 1015.9 151.7 1013.29 151.7Z"
          fill={theme.colors.grey.dark}
        />
        <path
          d="M124.3 170.8C127.89 170.8 130.8 167.89 130.8 164.3C130.8 160.71 127.89 157.8 124.3 157.8C120.71 157.8 117.8 160.71 117.8 164.3C117.8 167.89 120.71 170.8 124.3 170.8Z"
          fill="white"
        />
        <path
          d="M106.8 170.8C110.39 170.8 113.3 167.89 113.3 164.3C113.3 160.71 110.39 157.8 106.8 157.8C103.21 157.8 100.3 160.71 100.3 164.3C100.3 167.89 103.21 170.8 106.8 170.8Z"
          fill="white"
        />
        <path
          d="M89.3 170.8C92.8898 170.8 95.8 167.89 95.8 164.3C95.8 160.71 92.8898 157.8 89.3 157.8C85.7101 157.8 82.8 160.71 82.8 164.3C82.8 167.89 85.7101 170.8 89.3 170.8Z"
          fill="white"
        />
        <motion.path
          animate={inView ? "animate" : "initial"}
          d="M147.7 196.6H940.4C948.1 196.6 954.3 200.9 954.3 206.2V246.5C954.3 251.8 948.1 256.1 940.4 256.1H147.7C140 256.1 133.8 251.8 133.8 246.5V206.2C133.8 201 140 196.6 147.7 196.6Z"
          fill="#24DDD2"
          initial="initial"
          variants={logoContainerAnim}
        />
        <motion.g
          animate={inView ? "animate" : "initial"}
          clipPath="url(#clip1)"
          initial="initial"
          variants={logoAnim}
        >
          <path
            d="M721.297 209.351C727.97 209.351 733.392 214.602 733.392 221.106C733.392 227.591 727.989 232.86 721.297 232.86C714.605 232.86 709.202 227.61 709.202 221.106C709.202 217.992 710.472 215.007 712.747 212.797C715.003 210.567 718.093 209.333 721.297 209.351ZM721.297 202C710.453 202 701.656 210.549 701.656 221.087C701.656 231.626 710.453 240.175 721.297 240.175C732.141 240.175 740.938 231.626 740.938 221.087C740.938 210.549 732.141 202 721.297 202Z"
            fill="#5596CB"
          />
          <path
            d="M749.336 245.757L746.663 248.355C745.791 249.221 744.35 249.221 743.478 248.374C743.478 248.374 743.478 248.374 743.478 248.355L734.056 239.198C733.639 238.793 733.393 238.222 733.393 237.632V236.14L736.786 232.842H738.322C738.928 232.842 739.497 233.063 739.933 233.487L749.355 242.644C750.227 243.491 750.227 244.891 749.336 245.757Z"
            fill="#3B4C7A"
          />
          <path
            d="M548.018 243.749C539.676 243.749 534.197 239.033 534.197 231.995H538.785C538.785 236.711 542.539 239.641 548.018 239.641C554.217 239.641 557.251 237.098 557.251 233.155C557.251 230.207 555.886 228.181 547.923 226.873C539.885 225.509 535.297 223.077 535.297 217.034C535.297 211.009 540.245 207.104 547.923 207.104C555.848 207.104 560.758 211.267 560.758 218.047H556.265C556.265 213.939 553.402 211.212 547.98 211.212C542.406 211.212 539.79 213.589 539.79 216.887C539.79 219.927 541.458 221.456 548.871 222.764C556.796 224.238 561.706 226.412 561.706 233.155C561.687 240.046 556.113 243.749 548.018 243.749Z"
            fill="#3B4C7A"
          />
          <path
            d="M590.504 235.035C588.627 240.617 584.039 243.749 577.839 243.749C570.123 243.749 564.076 237.761 564.076 230.115C564.076 222.561 570.123 216.371 577.593 216.371C584.532 216.371 591.205 221.953 591.148 231.221H568.55C569.119 236.637 573.403 239.843 577.839 239.843C581.65 239.843 584.361 238.074 585.821 235.035H590.504V235.035ZM586.238 227.388C585.138 223.133 581.498 220.295 577.574 220.295C573.877 220.295 569.953 222.93 568.815 227.388H586.238Z"
            fill="#3B4C7A"
          />
          <path
            d="M612.438 240.156C610.505 242.441 607.376 243.749 603.206 243.749C597.67 243.749 592.722 241.317 592.722 235.698C592.722 230.318 596.741 227.886 603.68 227.536C610.258 227.223 612.381 226.062 612.381 223.98C612.381 221.898 610.353 220.332 605.803 220.332C602.978 220.332 599.281 221.198 598.807 224.993H594.219C594.523 219.779 598.959 216.371 605.86 216.371C613.064 216.371 616.704 219.613 616.704 224.477V238.867C616.704 240.193 617.064 242.109 617.481 243.123H613.121C612.761 242.386 612.495 241.372 612.438 240.156ZM612.381 229.102C610.599 230.631 607.433 230.981 604.248 231.184C598.409 231.534 597.101 233.524 597.101 235.698C597.101 238.535 599.926 239.751 603.357 239.751C607.945 239.751 612.268 237.817 612.381 233.118V229.102V229.102Z"
            fill="#3B4C7A"
          />
          <path
            d="M635.131 220.682C630.487 220.424 626.524 222.819 626.524 228.033V243.141H622.088V217.034H626.373V219.982C628.401 217.2 632.06 216.076 635.131 216.426V220.682V220.682Z"
            fill="#3B4C7A"
          />
          <path
            d="M648.649 243.749C641.236 243.749 635.188 237.669 635.188 230.115C635.188 222.561 641.236 216.371 648.649 216.371C654.64 216.371 659.341 219.816 661.161 225.085H656.422C654.905 222.248 652.137 220.314 648.649 220.314C643.701 220.314 639.625 224.717 639.625 230.097C639.625 235.477 643.701 239.77 648.649 239.77C652.194 239.77 654.962 237.835 656.422 234.998H661.161C659.398 240.304 654.659 243.749 648.649 243.749Z"
            fill="#3B4C7A"
          />
          <path
            d="M686.982 243.141H682.603V227.278C682.603 223.225 680.366 220.332 676.025 220.332C671.949 220.332 668.991 223.28 668.991 227.167V243.141H664.555V205.427H668.991V219.687C670.811 217.661 673.484 216.389 676.65 216.389C683.229 216.389 686.982 220.958 686.982 227.444V243.141V243.141Z"
            fill="#3B4C7A"
          />
          <path
            d="M421.888 225.399C421.888 235.643 413.85 243.749 403.954 243.749C394.057 243.749 386 235.698 386 225.399C386 215.155 394.038 207.104 403.935 207.104C414.001 207.104 421.888 214.971 421.888 225.399ZM417.509 225.399C417.509 217.292 411.556 211.212 403.954 211.212C396.503 211.212 390.398 217.439 390.398 225.399C390.398 233.413 396.503 239.585 403.954 239.585C411.404 239.585 417.509 233.358 417.509 225.399Z"
            fill="#DD242F"
          />
          <path
            d="M464.279 243.141H459.843V226.873C459.843 222.819 457.852 220.332 453.738 220.332C450.079 220.332 447.425 223.28 447.425 227.167V243.141H443.046V226.873C443.046 222.819 440.96 220.332 436.998 220.332C433.339 220.332 430.628 223.28 430.628 227.167V243.141H426.192V217.034H430.476V219.871C432.145 217.697 434.647 216.371 437.624 216.371C441.434 216.371 444.24 218.047 445.908 220.829C447.577 218.14 450.439 216.371 454.307 216.371C460.506 216.371 464.279 220.479 464.279 226.965V243.141V243.141Z"
            fill="#DD242F"
          />
          <path
            d="M492.11 243.141H487.73V227.278C487.73 223.28 485.436 220.332 481.152 220.332C477.076 220.332 474.118 223.28 474.118 227.167V243.141H469.682V217.034H473.967V219.871C475.787 217.697 478.555 216.371 481.796 216.371C488.375 216.371 492.129 220.94 492.129 227.425V243.141H492.11Z"
            fill="#DD242F"
          />
          <path
            d="M522.026 235.035C520.149 240.617 515.561 243.749 509.362 243.749C501.646 243.749 495.598 237.761 495.598 230.115C495.598 222.561 501.646 216.371 509.115 216.371C516.054 216.371 522.728 221.953 522.671 231.221H500.072C500.641 236.637 504.926 239.843 509.362 239.843C513.173 239.843 515.884 238.074 517.343 235.035H522.026V235.035ZM517.76 227.388C516.661 223.133 513.021 220.295 509.096 220.295C505.4 220.295 501.475 222.93 500.338 227.388H517.76Z"
            fill="#DD242F"
          />
        </motion.g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="745.7" width="1135.7" />
        </clipPath>
        <clipPath id="clip1">
          <rect fill="white" height="47" width="364" x="386" y="202" />
        </clipPath>
      </defs>
    </motion.svg>
  );
};

export default OmneSearchAnim;
