/* eslint-disable max-len */
/** @jsx jsx */
import { useContext } from "react";
import { jsx, useThemeUI } from "theme-ui";
import { motion } from "framer-motion";
import { IntersectionContext } from "../../hooks/intersection-observer";

const OmnePlayAnim = () => {
  const { theme } = useThemeUI();
  const { inView } = useContext(IntersectionContext);

  const computer = {
    initial: {
      opacity: 0,
      x: "-100%",
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 2,
        type: "spring",
        stiffness: 50,
      },
    },
  };

  const logoContainer = {
    initial: {
      scale: 0,
      opacity: 0,
    },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        delay: 0.25,
        duration: 2,
        type: "spring",
        stiffness: 50,
      },
    },
  };

  return (
    <motion.svg
      animate={inView ? "animate" : "initial"}
      initial="initial"
      sx={{
        width: "full",
        height: "full",
        transformOrigin: "center center !important",
      }}
      variants={computer}
      viewBox="0 0 850 582"
      x="0"
      y="0"
    >
      <ellipse
        cx="425"
        cy="549"
        fill={theme.colors.blue.dark}
        rx="425"
        ry="33"
      />
      <path
        d="M475.2 522.3l.3-14.2c13.3-.8 26.7-1.3 40-1.5 7.7-.1 15.6-.2 23.1 1.8 5.2 1.3 10.1 3.6 15.2 5.2 10.2 3.2 21 3.6 31.7 3.9 10.4.3 21.2.4 30.6-4.2 16.2-8 22.9-27.7 23.7-45.6s-2.8-36.1-.3-54"
        fill="none"
        stroke={theme.colors.blue.xdark}
        strokeMiterlimit="10"
        strokeWidth="12"
      />
      <path
        className="st2"
        d="M511.8 550l-178.2-2.2.5-4.6 8.7-75.4h156.4l11.6 75.4.8 5.7z"
        fill={theme.colors.grey.light}
      />
      <path
        className="st3 css-6wzr1u-SolutionsPage"
        d="M511.6 548.9h-88.9l-89.1-1.1.5-4.6h176.7z"
        fill={theme.colors.grey.dark}
      />
      <path
        className="st2"
        d="M303.9 544.3h236.4v5.7H303.9z"
        fill={theme.colors.grey.light}
      />
      <path
        d="M766 15c.1-8.2-6.5-14.9-14.7-15H90.8C82.6 0 76 6.7 76 15v400.3h690V15z"
        fill={theme.colors.blue.xdark}
      />
      <path
        className="st2"
        d="M76 411.3v46.9c0 8.2 6.6 14.8 14.8 14.8h660.3c8.2 0 14.8-6.6 14.8-14.8v-46.9H76z"
        fill={theme.colors.grey.light}
      />
      <path d="M104.6 25.2h636.2V385H104.6z" fill={theme.colors.blue.xlight} />
      <path
        className="st3"
        d="M439.9 472.9l71 71.4-10.9-71.4z"
        fill="#BBBDC0"
      />
      <motion.g
        animate={inView ? "animate" : "initial"}
        initial="initial"
        variants={logoContainer}
      >
        <path
          d="M650.1 158.4c-16.2 0-30.1 9.5-36.6 23.2-2.5 5.3-4 11.2-4 17.4 0 22.4 18.2 40.6 40.6 40.6 16.2 0 30.1-9.5 36.6-23.2 2.5-5.3 4-11.2 4-17.4-.1-22.5-18.2-40.6-40.6-40.6zm15.7 43.3l-25.6 14.4c-2.2 1.2-4.9-.4-4.9-2.9v-28.9c0-2.5 2.7-4.1 4.9-2.9l25.6 14.4c2.2 1.4 2.2 4.6 0 5.9z"
          fill="#5595cb"
        />
        <path
          d="M640.1 181.5c-2.2-1.2-4.9.4-4.9 2.9v28.9c0 2.5 2.7 4.1 4.9 2.9l25.6-14.4c2.2-1.3 2.2-4.5 0-5.7l-25.6-14.6z"
          fill={theme.colors.white}
        />
        <path
          className="st8"
          d="M548.1 180.5h8.3c.4 0 .6.1.7.5 3.6 9.7 7.1 19.5 10.7 29.2 1.1 3.1 2.2 6.1 3.4 9.2.1.2.1.3.3.6.2-.6.4-1 .5-1.5 4.5-12.5 9-25 13.4-37.5.2-.4.3-.6.8-.6h8.1c-.1.3-.2.5-.2.7-7.4 19.6-14.7 39.1-22.1 58.7-1.1 3-2.6 5.8-4.9 8.1-2.1 2.1-4.7 3.2-7.6 3.7-1.9.3-3.8.3-5.8.1-.3 0-.5 0-.5-.4v-7.1h.9c1.2-.1 2.5-.1 3.7-.3 2.2-.4 4-1.7 5-3.6.9-1.8 1.7-3.7 2.5-5.5.6-1.4 1.1-2.9 1.6-4.3.1-.3.1-.8 0-1.1-5.5-14.4-11.1-28.9-16.6-43.3-.6-1.6-1.3-3.3-1.9-4.9-.1-.2-.2-.4-.3-.7zM494.2 230.1h-8.1v-71.7h8.1v71.7zM480.2 179c-1-5.7-4-10.2-9.2-13-3.7-2-7.7-2.7-11.9-2.8h-27.4c-.8 0-.8 0-.8.8v66.2h8.1v-.9-24.6c0-1 0-1 .9-1h18.9c2.4 0 4.9-.2 7.2-.9 6.3-1.7 10.9-5.4 13.1-11.7 1.7-3.9 1.9-8 1.1-12.1zm-8.2 8.8c-1.1 4.2-4 6.6-8.2 7.6-1.5.4-3.1.5-4.7.6-6.4.1-12.8 0-19.3 0-.8 0-.8 0-.8-.8v-11.8-11.7c0-.8 0-.8.8-.8h19.5c2.3 0 4.5.3 6.6 1.2 3 1.3 5 3.4 5.9 6.5 1 3.1 1.1 6.2.2 9.2zM546.9 229.7c-.8-2.5-1.2-5.1-1.2-7.7v-24.9c0-1.3 0-2.7-.1-4-.2-2.9-1.2-5.4-3-7.7-2.2-2.7-5.2-4.2-8.5-5.1-3.4-.9-6.9-1.2-10.5-1-2.9.2-5.7.6-8.3 1.7-4.5 1.7-8 4.6-9.9 9.1-.7 1.7-1.1 3.6-1.2 5.5h8.1c.1 0 .4-.2.4-.4.7-4 3.1-6.4 6.8-7.6 4.1-1.3 8.3-1.2 12.5-.1 1.8.5 3.5 1.3 4.7 2.9 1.4 1.9 1.7 5.3-.9 7.1-1.3.9-2.8 1.4-4.3 1.8-3.6.9-7.3 1.1-11 1.3-3.1.2-6.1.7-9 1.7-3 1-5.6 2.5-7.5 5-1.6 2.1-2.4 4.6-2.6 7.3-.2 3 .2 5.8 1.6 8.5 1.8 3.4 4.8 5.5 8.4 6.8 5.1 1.8 10.3 2 15.6.9 4.2-.8 7.8-2.7 10.6-6 0 0 .1-.1.2-.1 0 .3.1.6.1.9.3 1.4.6 2.7.9 4.1.1.5.4.6.9.6h7.4c-.1-.4-.2-.5-.2-.6zm-9.2-25.3v6.4c0 4.8-2.2 8.4-6.5 10.6-2.6 1.4-5.3 1.9-8.2 2.1-2.6.2-5.2.1-7.8-.7-1.1-.4-2.3-.9-3.2-1.6-3.7-2.6-3.4-8.7.2-11.2 1.8-1.3 3.9-1.9 6-2.3 2.3-.3 4.5-.5 6.8-.7 3.3-.3 6.7-.7 9.8-2 1-.4 1.9-1 2.9-1.6v1z"
          fill="#3B4B79"
        />
        <motion.g>
          <motion.path
            className="st9"
            d="M221.2 196.7c0 19.5-14.9 34.9-33.2 34.9s-33.2-15.3-33.2-34.9c0-19.5 14.9-34.8 33.2-34.8 18.6-.1 33.2 14.9 33.2 34.8zm-8.1 0c0-15.4-11-27-25.1-27-13.8 0-25.1 11.9-25.1 27 0 15.2 11.3 27 25.1 27s25.1-11.9 25.1-27zM299.6 230.5h-8.2v-31c0-7.7-3.7-12.5-11.3-12.5-6.8 0-11.7 5.6-11.7 13v30.4h-8.1v-31c0-7.7-3.9-12.5-11.2-12.5-6.8 0-11.8 5.6-11.8 13v30.4h-8.2v-49.7h7.9v5.4c3.1-4.2 7.7-6.7 13.2-6.7 7 0 12.3 3.2 15.3 8.5 3.1-5.1 8.4-8.5 15.5-8.5 11.5 0 18.4 7.8 18.4 20.2v31zM351.1 230.5H343v-30.2c0-7.6-4.2-13.2-12.2-13.2-7.5 0-13 5.6-13 13v30.4h-8.2v-49.7h7.9v5.4c3.4-4.2 8.5-6.7 14.5-6.7 12.2 0 19.1 8.7 19.1 21v30zM406.5 215c-3.5 10.6-12 16.6-23.5 16.6-14.3 0-25.5-11.4-25.5-26 0-14.4 11.2-26.2 25-26.2 12.8 0 25.2 10.6 25.1 28.3h-41.8c1.1 10.3 9 16.4 17.2 16.4 7 0 12.1-3.4 14.8-9.2h8.7zm-7.9-14.5c-2-8.1-8.8-13.5-16-13.5-6.9 0-14.1 5-16.2 13.5h32.2z"
            fill="#DD242F"
          />
        </motion.g>
      </motion.g>
    </motion.svg>
  );
};

export default OmnePlayAnim;
