export const toTitleCase = (text) => {
  const spaced = text.replace(new RegExp("_", "g"), " ");
  return spaced
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export const toCamelCase = (text) =>
  text.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return "";
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });

export const toHyphenatedCase = (text) => {
  const spaced = text.replace(new RegExp("_", "g"), "-");
  return spaced
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};
