/* eslint-disable max-len */
/** @jsx jsx */
import PropTypes from "prop-types";
import { Box, Container, Grid, Heading, jsx, Text } from "theme-ui";
import { Stack } from "raam";
import { useStaticQuery, graphql } from "gatsby";
import CaseStudyCard from "./Cards/CaseStudyCard";

const StudiesOverview = ({ id }) => {
  const {
    moodyGardens: {
      childImageSharp: { fluid: moodyGardens },
    },
    visitJax: {
      childImageSharp: { fluid: visitJax },
    },
    napleton: {
      childImageSharp: { fluid: napleton },
    },
    baptist: {
      childImageSharp: { fluid: baptist },
    },
  } = useStaticQuery(graphql`
    query CaseStudyCardQuery {
      moodyGardens: file(relativePath: { eq: "clients/moody.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      visitJax: file(relativePath: { eq: "clients/visitJax.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      napleton: file(relativePath: { eq: "clients/napleton.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      baptist: file(relativePath: { eq: "clients/baptist.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Box
      bg="white"
      id={id}
      px="3"
      py="7"
      sx={{ width: "screenWidth", position: "relative" }}
    >
      <Container sx={{ position: "relative", zIndex: "2" }}>
        <Grid
          columns={["1fr", null, "1fr 1fr"]}
          sx={{
            gridTemplateRows: ["repeat(17, 96px)", null, "repeat(9, 156px)"],
            rowGap: ["4", null, "8"],
            columnGap: "4",
          }}
        >
          <Grid
            color="black"
            p="3"
            sx={{ gridRow: "span 1" }}
            variant="placeCenter"
          >
            <Heading as="h3" color="blue.xdark" variant="heading">
              Our Work
            </Heading>
          </Grid>
          <CaseStudyCard
            bg="blue.light"
            excerpt="Delivering measurable return on investment for online ticket sales"
            heading="Moody Gardens"
            image={moodyGardens}
          >
            <Stack gap="3">
              <Text as="p">
                The combination of
                <Text as="span" sx={{ fontWeight: "bold" }}>
                  {" "}
                  OmneTarget
                </Text>
                <Text as="span">
                  {" "}
                  display, video and email marketing delivered over $1 million
                  in annual ticket sales.
                </Text>
              </Text>
              <Text as="p">
                <Text as="span" sx={{ fontWeight: "bold" }}>
                  OmneConvert
                </Text>
                <Text as="span">
                  {" "}
                  directly identified ticket sales from each source of digital
                  media which drove better advertising decisions as the campaign
                  matured.
                </Text>
              </Text>
            </Stack>
          </CaseStudyCard>
          <CaseStudyCard
            bg="blue.dark"
            excerpt="Fueled primary category growth and an impressive lift in website traffic"
            heading="Visit Jacksonville"
            image={visitJax}
          >
            <Stack gap="3">
              <Text as="p">
                Our partnership with a local tourism bureau creatively
                integrated the power of local influence and out-of-market
                presence through
                <Text as="span" sx={{ fontWeight: "bold" }}>
                  {" "}
                  OmnePlay
                </Text>
                <Text as="span"> and </Text>
                <Text as="span" sx={{ fontWeight: "bold" }}>
                  OmneTarget
                </Text>
              </Text>
            </Stack>
          </CaseStudyCard>
          <CaseStudyCard
            bg="red.dark"
            excerpt="Using OmneConvert technology to match ad dollars to car sales"
            heading="Napleton Chrysler Jeep Dodge Ram"
            image={napleton}
          >
            <Stack gap="3">
              <Text as="p">
                <Text as="span" sx={{ fontWeight: "bold" }}>
                  OmneTarget&nbsp;
                </Text>
                isolated the top 5 market competitors and delivered a focused
                display, email and YouTube campaign to in-market buyers.
              </Text>
              <Text as="p">
                Using
                <Text as="span" sx={{ fontWeight: "bold" }}>
                  &nbsp;OmneConvert&nbsp;
                </Text>
                matching technology, the digital ad dollars were proven to
                deliver 89 car sales in a single month.
              </Text>
            </Stack>
          </CaseStudyCard>
          <CaseStudyCard
            bg="blue.mid"
            description=""
            excerpt="Educating the public and growing subscriptions through the power of interactive and engaging content."
            heading="Baptist Health System"
            image={baptist}
          >
            <Stack gap="3">
              <Text>
                Educating the public and growing subscriptions through the power
                of interactive and engaging content.
              </Text>
              <Text>
                Through the power of
                <Text as="span" sx={{ fontWeight: "bold" }}>
                  {" "}
                  OmneNative
                </Text>
                <Text as="span"> and </Text>
                <Text as="span" sx={{ fontWeight: "bold" }}>
                  OmneSocial,
                </Text>
                <Text as="span"> </Text>
                our team of experts elevated the presence of their new medical
                blog.
              </Text>
              <Text>
                These efforts accounted for a 49% increase in unique pageviews,
                26% of total traffic and the addition of more than 1,500
                subscribers to their internal database.
              </Text>
            </Stack>
          </CaseStudyCard>
        </Grid>
      </Container>
    </Box>
  );
};

StudiesOverview.propTypes = {
  id: PropTypes.string.isRequired,
};

export default StudiesOverview;
