/** @jsx jsx */
import { Box, Container, jsx, Grid, Heading, Text } from "theme-ui";
import { Stack } from "raam";
import { IntersectionObserver } from "../hooks/intersection-observer";
import OmnePlayAnim from "./SolutionsAnims/OmnePlay";
import OmneSearchAnim from "./SolutionsAnims/OmneSearch";
import OmneSocialAnim from "./SolutionsAnims/OmneSocial";
import OmneNativeAnim from "./SolutionsAnims/OmneNative";
import OmneTargetAnim from "./SolutionsAnims/OmneTarget";
import OmneConvertAnim from "./SolutionsAnims/OmneConvert";
import OmneXAnim from "./SolutionsAnims/OmneX";

const SolutionsOverview = () => (
  <Stack gap="0">
    <Box bg="blue.xlight" color="blue.xdark" p="7">
      <Container px="3">
        <Grid columns={["1fr", null, "repeat(2, 1fr)"]} gap="5">
          <IntersectionObserver>
            <OmnePlayAnim />
          </IntersectionObserver>
          <Grid variant="placeCenter">
            <Stack gap="3">
              <Heading variant="heading.three">TV. Targeted.</Heading>
              <Text variant="body.lg">
                Omne Play brings your message to the targeted audiences you’re
                after, whether they’re watching OTT, CTV, or local streaming.
              </Text>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
    <Box bg="white" color="blue.xdark" p="7">
      <Container px="3">
        <Grid columns={["1fr", null, "repeat(2, 1fr)"]} gap="5">
          <Grid sx={{ order: ["2", null, "1"] }} variant="placeCenter">
            <Stack gap="3">
              <Heading variant="heading.three">
                They’re looking for you. Be there.
              </Heading>
              <Text variant="body.lg">
                As a Google Premier Partner, our team of search specialists will
                maximize your digital visibility, ensuring that no matter where
                your audience is looking, you’ll be what they find.
              </Text>
            </Stack>
          </Grid>
          <IntersectionObserver sx={{ order: ["1", null, "2"] }}>
            <OmneSearchAnim />
          </IntersectionObserver>
        </Grid>
      </Container>
    </Box>
    <Box bg="blue.xlight" color="blue.xdark" p="7">
      <Container px="3">
        <Grid columns={["1fr", null, "repeat(2, 1fr)"]} gap="5">
          <IntersectionObserver>
            <OmneSocialAnim />
          </IntersectionObserver>
          <Grid variant="placeCenter">
            <Stack gap="3">
              <Heading variant="heading.three">
                High. Quality. Audience.
              </Heading>
              <Text variant="body.lg">
                Just being on Facebook, Instagram, Twitter, Snapchat, and
                LinkedIn isn’t enough - you need highly-targeted,
                custom-prospected audiences ready for conversion. We’re here to
                help.
              </Text>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
    <Box bg="white" color="blue.xdark" p="7">
      <Container px="3">
        <Grid columns={["1fr", null, "repeat(2, 1fr)"]} gap="5">
          <Grid sx={{ order: ["2", null, "1"] }} variant="placeCenter">
            <Stack gap="3">
              <Heading variant="heading.three">
                Branded storytelling that works.
              </Heading>
              <Text variant="body.lg">
                Your content and messaging, integrated into
                contextually-relevant, respected environments by our content
                team, reinforcing brand credibility and increasing the potential
                for readers to engage with your brand.
              </Text>
            </Stack>
          </Grid>
          <IntersectionObserver sx={{ order: ["1", null, "2"] }}>
            <OmneNativeAnim />
          </IntersectionObserver>
        </Grid>
      </Container>
    </Box>
    <Box bg="blue.xlight" color="blue.xdark" p="7">
      <Container px="3">
        <Grid columns={["1fr", null, "repeat(2, 1fr)"]} gap="5">
          <IntersectionObserver>
            <OmneTargetAnim />
          </IntersectionObserver>
          <Grid variant="placeCenter">
            <Stack gap="3">
              <Heading variant="heading.three">
                No impression is wasted on our watch.
              </Heading>
              <Text variant="body.lg">
                We leverage machine learning to optimize the conversion points
                where your ads are receiving the most engagement, sending higher
                quality traffic to your website.
              </Text>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
    <Box bg="white" color="blue.xdark" p="7">
      <Container px="3">
        <Grid columns={["1fr", null, "repeat(2, 1fr)"]} gap="5">
          <Grid sx={{ order: ["2", null, "1"] }} variant="placeCenter">
            <Stack gap="3">
              <Heading variant="heading.three">
                Know what’s working. Fix what’s not.
              </Heading>
              <Text variant="body.lg">
                Our obsession with providing great results inspired the creation
                of Omne Convert, a customized measurement suite that uses
                proprietary tracking strategies to ensure no ad dollar goes to
                waste.
              </Text>
            </Stack>
          </Grid>
          <IntersectionObserver sx={{ order: ["1", null, "2"] }}>
            <OmneConvertAnim />
          </IntersectionObserver>
        </Grid>
      </Container>
    </Box>
    <Box bg="blue.xlight" color="blue.xdark" p="7">
      <Container px="3">
        <Grid columns={["1fr", null, "repeat(2, 1fr)"]} gap="5">
          <IntersectionObserver>
            <OmneXAnim />
          </IntersectionObserver>
          <Grid variant="placeCenter">
            <Stack gap="3">
              <Heading variant="heading.three">
                Xperts laser-focused on user Xperience.
              </Heading>
              <Text variant="body.lg">
                OmneX combines in-house journalists, award-winning creative
                services teams and digital developers to create a best-in-class
                team working around the clock to ensure our clients, and our
                clients’ clients, receive the highest quality product.
              </Text>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </Stack>
);

export default SolutionsOverview;
