/* eslint-disable max-len */
/** @jsx jsx */
import { useContext } from "react";
import { jsx } from "theme-ui";
import { motion } from "framer-motion";
import { IntersectionContext } from "../../hooks/intersection-observer";

const OmneXAnim = () => {
  const { inView } = useContext(IntersectionContext);

  const wrapper = {
    initial: {
      opacity: 0,
      x: "-100%",
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.1,
        type: "spring",
        stiffness: 50,
      },
    },
  };

  const wireframes = {
    initial: {
      scale: 1,
    },
    animate: {
      scale: 0,
      transition: {
        duration: 0.5,
        type: "spring",
        stiffness: 50,
        when: "afterChildren",
        staggerChildren: 0.5,
        staggerDirection: 1,
      },
    },
  };

  const wireframe = {
    initial: {
      scale: 0,
      y: -32,
    },
    animate: {
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 50,
        when: "beforeChildren",
        staggerChildren: 0.05,
      },
    },
  };

  const wireframeChildren = {
    initial: {
      scale: 0,
      y: -32,
    },
    animate: {
      scale: 1,
      y: 0,
    },
  };

  const finished = {
    initial: {
      scale: 0,
    },
    animate: {
      scale: 1,
      transition: {
        delay: 5.5,
        duration: 0.5,
        type: "spring",
        stiffness: 75,
        damping: 150,
      },
    },
  };

  return (
    <motion.svg
      animate={inView ? "animate" : "initial"}
      fill="none"
      initial="initial"
      sx={{
        width: "full",
        height: "full",
        transformOrigin: "center center !important",
      }}
      variants={wrapper}
      viewBox="0 0 1052 698"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M546.335 132.909C480.954 143.828 415.473 137.947 352.437 129.812C289.4 121.678 226.511 111.29 160.993 113.306C118.871 114.616 72.0146 122.896 42.8681 148.307C14.8296 172.765 0.999905 202.976 1 236.007C1.0001 269.038 13.4194 282.706 28.8122 300.981C39.4983 313.677 55.8343 322.883 68.187 334.618C111.225 375.454 99.0184 438.715 71.7674 492.293C58.9477 517.42 43.2802 542.098 34.6818 567.856C26.0834 593.615 25.2318 621.398 41.4396 642.769C57.5009 663.911 88.2224 676.103 120.189 682.625C185.103 695.88 257.15 689.037 326.834 679.529C481.064 658.341 633.619 624.073 785.624 589.905C841.912 577.255 898.447 564.54 952.601 545.936C982.672 535.621 1013.27 522.641 1032.52 502.122C1056.96 476.061 1057.18 440.089 1033.04 418.26C992.571 381.701 901.917 388.535 870.445 346.892C853.101 323.992 860.161 293.204 873.412 266.126C901.798 208.05 957.427 151.028 949.414 92.6676C943.92 52.5823 905.113 19.0282 853.559 9.74889C799.533 0.0206919 730.481 16.2618 699.924 56.109C668.415 97.1286 604.06 123.272 546.335 132.909Z"
          fill="#3A4B79"
        />
        <motion.g
          animate={inView ? "animate" : "initial"}
          className="wiereframes"
          initial="initial"
          sx={{ transformOrigin: "center center !important" }}
          variants={wireframes}
        >
          <motion.g
            className="wireframe1"
            sx={{ transformOrigin: "center center !important" }}
            variants={wireframe}
          >
            <motion.path
              d="M27.0672 163.701C27.0672 163.701 238.469 172.33 267.231 163.701C267.231 163.701 251.412 517.476 257.165 528.981C257.165 528.981 22.7529 541.924 5.49563 528.981C-11.7617 516.038 27.0672 163.701 27.0672 163.701Z"
              fill="white"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M180.945 458.513H67.3342C58.7056 458.513 58.7056 498.78 58.7056 498.78H180.945V458.513Z"
              fill="white"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M58.7056 229.854C58.7056 229.854 156.497 385.17 189.573 399.551L58.7056 229.854Z"
              fill="white"
              variants={wireframeChildren}
            />
            <motion.path
              d="M58.7056 229.854C58.7056 229.854 156.497 385.17 189.573 399.551"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M189.573 229.854C189.573 229.854 96.0964 352.094 81.7153 393.799L189.573 229.854Z"
              fill="white"
              variants={wireframeChildren}
            />
            <motion.path
              d="M189.573 229.854C189.573 229.854 96.0964 352.094 81.7153 393.799"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
          </motion.g>
          <motion.g
            className="arrow1"
            sx={{ transformOrigin: "center center !important" }}
            variants={wireframe}
          >
            <motion.path
              d="M300.308 329.084C300.308 329.084 356.394 326.208 366.461 329.084"
              stroke="white"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M366.461 298.883C366.461 298.883 390.909 323.331 390.909 329.084C390.909 334.836 359.27 354.97 359.27 354.97"
              stroke="white"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
          </motion.g>
          <motion.g
            className="wireframe2"
            sx={{ transformOrigin: "center center !important" }}
            variants={wireframe}
          >
            <motion.path
              d="M423.985 163.701C423.985 163.701 626.758 153.635 664.149 163.701C664.149 163.701 648.33 517.476 654.082 528.981C654.082 528.981 394.993 549.244 402.413 528.981C418.233 485.838 423.985 163.701 423.985 163.701Z"
              fill="white"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M518.195 462.109L526.824 470.737L542.643 449.166"
              fill="white"
              variants={wireframeChildren}
            />
            <motion.path
              d="M518.195 462.109L526.824 470.737L542.643 449.166"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M594.099 442.694H480.488C471.86 442.694 471.86 482.961 471.86 482.961H594.099V442.694Z"
              fill="white"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M614.908 373.665C626.485 373.665 475.958 386.608 462.469 373.665C455.365 366.863 450.964 413.932 450.964 413.932H614.908V373.665Z"
              fill="white"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M614.908 314.703H462.469C450.964 314.703 450.964 354.97 450.964 354.97H614.908V314.703Z"
              fill="white"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M503.009 190.681C503.009 190.681 548.137 262.37 563.41 269L503.009 190.681Z"
              fill="white"
              variants={wireframeChildren}
            />
            <motion.path
              d="M503.009 190.681C503.009 190.681 548.137 262.37 563.41 269"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M563.41 190.681C563.41 190.681 520.266 247.098 513.622 266.354L563.41 190.681Z"
              fill="white"
              variants={wireframeChildren}
            />
            <motion.path
              d="M563.41 190.681C563.41 190.681 520.266 247.098 513.622 266.354"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
          </motion.g>
          <motion.g
            className="arrow2"
            sx={{ transformOrigin: "center center !important" }}
            variants={wireframe}
          >
            <motion.path
              d="M688.597 329.084C688.597 329.084 744.683 326.208 754.75 329.084"
              stroke="white"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M754.75 298.883C754.75 298.883 779.198 323.331 779.198 329.084C779.198 334.836 747.559 354.97 747.559 354.97"
              stroke="white"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
          </motion.g>
          <motion.g
            className="wireframe3"
            sx={{ transformOrigin: "center center !important" }}
            variants={wireframe}
          >
            <motion.path
              d="M810.836 166.578C810.836 166.578 1012.17 191.025 1051 166.578C1051 166.578 1035.18 520.352 1040.93 531.857C1040.93 531.857 773.733 546.828 789.264 531.857C828.093 494.466 810.836 166.578 810.836 166.578Z"
              fill="white"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M812.044 193.557C812.044 193.557 1035.24 210.052 1049.59 199.094L812.044 193.557Z"
              fill="white"
              variants={wireframeChildren}
            />
            <motion.path
              d="M812.044 193.557C812.044 193.557 1035.24 210.052 1049.59 199.094"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M840.691 408.123L993.131 408.123C1004.64 408.123 1004.64 386.81 1004.64 386.81L840.691 386.81L840.691 408.123Z"
              fill="white"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M1004.64 355.243C1016.21 355.243 865.685 362.089 852.196 355.243C845.092 351.648 840.691 376.542 840.691 376.542H1004.64V355.243Z"
              fill="white"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M1004.64 324.05H852.196C840.691 324.05 840.691 345.363 840.691 345.363H1004.64V324.05Z"
              fill="white"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M888.235 220.133C887.401 230.775 889.946 241.345 891.658 251.886C893.817 265.194 894.674 278.68 894.217 292.153C871.524 293.534 848.802 293.865 826.051 293.146C828.165 293.218 827.633 245.055 827.834 240.626C828.093 234.96 826.885 225.21 833.457 223.081C840.36 220.823 850.082 222.218 857.33 221.873C867.627 221.394 877.929 220.814 888.235 220.133Z"
              fill="white"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M838.807 229.135C838.807 229.135 867.814 275.155 877.636 279.469L838.807 229.135Z"
              fill="white"
              variants={wireframeChildren}
            />
            <motion.path
              d="M838.807 229.135C838.807 229.135 867.814 275.155 877.636 279.469"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M877.636 229.135C877.636 229.135 849.909 265.462 845.696 277.772L877.636 229.135Z"
              fill="white"
              variants={wireframeChildren}
            />
            <motion.path
              d="M877.636 229.135C877.636 229.135 849.909 265.462 845.696 277.772"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="3"
              variants={wireframeChildren}
            />
            <motion.path
              d="M1019.94 197.066C1025.46 196.127 1031.04 195.503 1036.63 195.196L1019.94 197.066Z"
              fill="white"
              variants={wireframeChildren}
            />
            <motion.path
              d="M1019.94 197.066C1025.46 196.127 1031.04 195.503 1036.63 195.196"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="2"
              variants={wireframeChildren}
            />
            <motion.path
              d="M1019.97 189.027C1026.41 190.161 1032.97 190.523 1039.5 190.105L1019.97 189.027Z"
              fill="white"
              variants={wireframeChildren}
            />
            <motion.path
              d="M1019.97 189.027C1026.41 190.161 1032.97 190.523 1039.5 190.105"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="2"
              variants={wireframeChildren}
            />
            <motion.path
              d="M1021.5 183.361C1024.55 182.714 1027.69 183.634 1030.78 184.109C1033.87 184.583 1037.38 184.526 1039.62 182.368"
              fill="white"
              variants={wireframeChildren}
            />
            <motion.path
              d="M1021.5 183.361C1024.55 182.714 1027.69 183.634 1030.78 184.109C1033.87 184.583 1037.38 184.526 1039.62 182.368"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="2"
              variants={wireframeChildren}
            />
            <motion.path
              d="M824.599 186.366C827.299 187.615 830.255 188.211 833.227 188.106C835.101 188.363 837.006 187.95 838.606 186.941C840.49 185.417 840.648 182.627 840.648 180.196C840.703 179.458 840.595 178.717 840.332 178.025C839.969 177.363 839.438 176.807 838.793 176.414C835.241 173.941 828.64 172.934 824.484 174.617C818.789 176.918 819.709 183.705 824.599 186.366Z"
              fill="white"
              stroke="#33313F"
              strokeMiterlimit="10"
              strokeWidth="2"
              variants={wireframeChildren}
            />
          </motion.g>
        </motion.g>
        <motion.g
          animate={inView ? "animate" : "initial"}
          className="finished"
          initial="initial"
          variants={finished}
        >
          <path
            d="M698.671 158.044H692.785C691.582 158.044 690.607 159.019 690.607 160.221V240.322C690.607 241.525 691.582 242.5 692.785 242.5H698.671C699.873 242.5 700.848 241.525 700.848 240.322V160.221C700.848 159.019 699.873 158.044 698.671 158.044Z"
            fill="#E5E7EB"
            opacity="0.9"
          />
          <path
            d="M353.649 91.8821H352.251C351.048 91.8821 350.073 92.857 350.073 94.0596V117.508C350.073 118.711 351.048 119.685 352.251 119.685H353.649C354.851 119.685 355.826 118.711 355.826 117.508V94.0596C355.826 92.857 354.851 91.8821 353.649 91.8821Z"
            fill="#E5E7EB"
            opacity="0.9"
          />
          <path
            d="M354.172 142.782H352.042C350.839 142.782 349.864 143.757 349.864 144.96V188.994C349.864 190.197 350.839 191.172 352.042 191.172H354.172C355.374 191.172 356.349 190.197 356.349 188.994V144.96C356.349 143.757 355.374 142.782 354.172 142.782Z"
            fill="#E5E7EB"
            opacity="0.9"
          />
          <path
            d="M353.962 208.107H352.146C350.944 208.107 349.969 209.082 349.969 210.284V254.737C349.969 255.94 350.944 256.915 352.146 256.915H353.962C355.165 256.915 356.14 255.94 356.14 254.737V210.284C356.14 209.082 355.165 208.107 353.962 208.107Z"
            fill="#E5E7EB"
            opacity="0.9"
          />
          <path
            d="M659.058 0H390.4C369.924 0 353.325 16.5987 353.325 37.0743V659.636C353.325 680.111 369.924 696.71 390.4 696.71H659.058C679.533 696.71 696.132 680.111 696.132 659.636V37.0743C696.132 16.5987 679.533 0 659.058 0Z"
            fill="#1E2F46"
          />
          <path
            d="M535.863 20.8049H495.347C493.315 20.8049 491.667 22.4525 491.667 24.4848V26.8429C491.667 28.8753 493.315 30.5228 495.347 30.5228H535.863C537.896 30.5228 539.543 28.8753 539.543 26.8429V24.4848C539.543 22.4525 537.896 20.8049 535.863 20.8049Z"
            fill="#E5E7EB"
            opacity="0.9"
          />
          <path
            d="M552.285 31.179C555.331 31.179 557.8 28.7098 557.8 25.664C557.8 22.6181 555.331 20.1489 552.285 20.1489C549.239 20.1489 546.77 22.6181 546.77 25.664C546.77 28.7098 549.239 31.179 552.285 31.179Z"
            fill="#E5E7EB"
            opacity="0.9"
          />
          <path
            d="M647.742 18.1521H608.5V22.9064C608.497 28.8513 606.134 34.5519 601.93 38.7547C597.725 42.9574 592.023 45.3184 586.079 45.3184H460.963C455.019 45.3184 449.317 42.9574 445.112 38.7547C440.908 34.5519 438.545 28.8513 438.542 22.9064V18.1521H401.724C394.504 18.1521 387.58 21.0203 382.475 26.1256C377.369 31.231 374.501 38.1554 374.501 45.3754V651.344C374.501 658.564 377.369 665.489 382.475 670.594C387.58 675.699 394.504 678.568 401.724 678.568H647.742C654.962 678.568 661.887 675.699 666.992 670.594C672.097 665.489 674.966 658.564 674.966 651.344V45.3754C674.966 38.1554 672.097 31.231 666.992 26.1256C661.887 21.0203 654.962 18.1521 647.742 18.1521Z"
            fill="white"
          />
          <path d="M620.628 629H528V635H620.628V629Z" fill="#D0D2D5" />
          <path d="M656.102 615H528V621H656.102V615Z" fill="#D0D2D5" />
          <path d="M663 601H528V607H663V601Z" fill="#D0D2D5" />
          <path d="M663 527H528V593H663V527Z" fill="#5495CA" />
          <path d="M477.628 629H385V635H477.628V629Z" fill="#D0D2D5" />
          <path d="M513.102 615H385V621H513.102V615Z" fill="#D0D2D5" />
          <path d="M520 601H385V607H520V601Z" fill="#D0D2D5" />
          <path d="M520 527H385V593H520V527Z" fill="#5495CA" />
          <path d="M620.628 505H528V511H620.628V505Z" fill="#D0D2D5" />
          <path d="M656.102 491H528V497H656.102V491Z" fill="#D0D2D5" />
          <path d="M663 477H528V483H663V477Z" fill="#D0D2D5" />
          <path d="M663 403H528V469H663V403Z" fill="#5495CA" />
          <path d="M477.628 505H385V511H477.628V505Z" fill="#D0D2D5" />
          <path d="M513.102 491H385V497H513.102V491Z" fill="#D0D2D5" />
          <path d="M520 477H385V483H520V477Z" fill="#D0D2D5" />
          <path d="M520 403H385V469H520V403Z" fill="#5495CA" />
          <path d="M663 132H385V387H663V132Z" fill="#D0D2D5" />
          <path d="M663 84H385V124H663V84Z" fill="#CB3938" />
          <path d="M663 132H385V387H663V132Z" fill="#EEF4F9" />
          <path d="M663 84H385V124H663V84Z" fill="#CB3938" />
          <path
            d="M450.163 259.918C450.163 275.361 438.358 287.591 423.793 287.591C409.228 287.591 397.423 275.436 397.423 259.918C397.423 244.474 409.228 232.319 423.793 232.319C438.589 232.319 450.163 244.168 450.163 259.918ZM443.726 259.918C443.726 247.688 434.986 238.516 423.793 238.516C412.832 238.516 403.86 247.919 403.86 259.918C403.86 271.998 412.825 281.319 423.793 281.319C434.761 281.319 443.726 271.923 443.726 259.918Z"
            fill="#DC252E"
          />
          <path
            d="M512.467 286.673H505.947V262.14C505.947 256.025 503.031 252.274 496.983 252.274C491.614 252.274 487.704 256.711 487.704 262.595V286.673H481.267V262.14C481.267 256.025 478.201 252.274 472.377 252.274C467.009 252.274 463.023 256.711 463.023 262.595V286.673H456.511V247.3H462.799V251.58C465.252 248.292 468.93 246.308 473.296 246.308C478.889 246.308 483.031 248.829 485.484 253.035C487.936 248.985 492.153 246.308 497.82 246.308C506.942 246.308 512.46 252.498 512.46 262.289V286.673H512.467Z"
            fill="#DC252E"
          />
          <path
            d="M553.35 286.673H546.912V262.751C546.912 256.711 543.54 252.282 537.252 252.282C531.271 252.282 526.905 256.719 526.905 262.602V286.681H520.392V247.308H526.68V251.588C529.365 248.299 533.424 246.316 538.18 246.316C547.84 246.316 553.357 253.199 553.357 262.982V286.673H553.35Z"
            fill="#DC252E"
          />
          <path
            d="M597.336 274.444C594.577 282.855 587.833 287.591 578.711 287.591C567.369 287.591 558.471 278.568 558.471 267.024C558.471 255.63 567.361 246.308 578.322 246.308C588.52 246.308 598.33 254.72 598.255 268.709H565.066C565.911 276.89 572.191 281.707 578.711 281.707C584.304 281.707 588.289 279.03 590.434 274.444H597.336ZM591.048 262.9C589.44 256.48 584.072 252.199 578.322 252.199C572.879 252.199 567.13 256.174 565.447 262.9H591.048Z"
            fill="#DC252E"
          />
          <path
            d="M640.783 286.681L628.753 268.337L616.648 286.681H603.235L621.934 259.47L604.005 233.177H617.799L629.067 250.529L640.409 233.177H653.822L635.893 259.314L654.592 286.674H640.783V286.681Z"
            fill="#3B4D7B"
          />
        </motion.g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="697.24" width="1051.2" />
        </clipPath>
      </defs>
    </motion.svg>
  );
};

export default OmneXAnim;
