/** @jsx jsx */
import PropTypes from "prop-types";
import { Box, Container, Flex, Heading, jsx } from "theme-ui";
import { Stack } from "raam";
import {
  motion,
  useViewportScroll,
  useTransform,
  useSpring,
} from "framer-motion";
import { Link as ScrollLink } from "react-scroll";
import StyledLink from "./Link.styled";
import Header from "./Header";

const Hero = ({ id, pathname }) => {
  const { scrollYProgress } = useViewportScroll();
  const scrollProgress = useTransform(scrollYProgress, [0, 1], [0, 540]);
  const springRotate = useSpring(scrollProgress, {
    stiffness: 25,
    damping: 10,
  });

  return (
    <Box id={id} sx={{ position: "relative" }}>
      <Header
        pathname={pathname}
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "2",
        }}
      />
      <Flex
        color="white"
        px="3"
        py="7"
        sx={{
          height: "screenHeight",
          alignItems: "center",
          background: "linear-gradient(to right top, #07112b, #142b53)",
          position: "relative",
        }}
      >
        <motion.svg
          animate={{ opacity: 0.15, scale: 1, x: "-50%" }}
          initial={{ opacity: 0, scale: 0.25, x: "-50%" }}
          style={{
            rotate: springRotate,
          }}
          sx={{
            position: "absolute",
            zIndex: "1",
            height: "125vh",
            transformOrigin: "center center !important",
          }}
          transition={{
            delay: 1.5,
            duration: 2,
            type: "spring",
            stiffness: 50,
          }}
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            // eslint-disable-next-line max-len
            d="M73.7 358.3c-4.8-18.4-6.4-36.8-6.4-54.4.8-43.2 16-84 41.6-117.6 26.4-55.2 82.4-92.8 147.1-92.8 90.4 0 163.1 72.8 163.1 163.2 0 24.8-5.6 48.8-16 69.6 25.6-33.6 40.8-74.4 41.6-117.6.8-17.6-1.6-36-6.4-54.4C411.1 48.7 308-15.3 203.2 5.5c-3.2.8-7.2 1.6-10.4 2.4s-6.4 1.6-10.4 3.2C52.1 49.5-26.3 185.5 8.1 319.1s167.1 216 300.7 187.2c-104 20.8-208-43.2-235.1-148z"
            fill="#5596cb"
          />
          <path
            // eslint-disable-next-line max-len
            d="M503.9 192.7C470.3 59.1 336.8-22.5 203.2 5.5c104.8-20.8 208.7 43.2 235.1 148 4.8 18.4 6.4 36.8 6.4 54.4-.8 43.2-16 84-41.6 117.6-26.4 55.2-82.4 93.6-147.1 93.6-90.4 0-163.1-72.8-163.1-163.2 0-24.8 5.6-48.8 16-69.6-25.6 33.6-40 74.4-41.6 117.6-.8 17.6 1.6 36 6.4 54.4C100 463.1 204 527.1 308.8 506.3c3.2-.8 6.4-1.6 10.4-2.4 3.2-.8 6.4-1.6 10.4-3.2 130.3-38.4 208.7-174.4 174.3-308z"
            fill="#3b4c7a"
          />
        </motion.svg>
        <Container sx={{ position: "relative", zIndex: "1" }}>
          <Flex
            sx={{
              alignItems: "center",
              flexDirection: ["column-reverse", null, null, "row"],
              height: "full",
              width: "full",
            }}
          >
            <Stack gap="7" sx={{ width: ["full", null, null, "3/5"] }}>
              <Heading as="h1" variant="heading.two">
                We connect companies with customers using data-driven marketing.
              </Heading>
              <Flex sx={{ alignItems: "flex-end" }}>
                <StyledLink
                  as={ScrollLink}
                  color="white"
                  fontSize="4"
                  fontWeight="bold"
                  hoverColor="blue.light"
                  scrollTo="contact"
                  textDecoration="none"
                  smooth
                >
                  Let’s build your next success
                </StyledLink>
                <Box
                  color="red.light"
                  sx={{
                    display: "inline-block",
                    width: 0,
                    height: 0,
                    borderStyle: "solid",
                    borderWidth: "12px 0 12px 12px",
                    borderColor: "transparent transparent transparent #dd232f",
                    ml: "2",
                  }}
                />
              </Flex>
            </Stack>

            <Flex
              sx={{
                justifyContent: "center",
                width: ["full", null, null, "2/5"],
              }}
            >
              <motion.svg
                animate={{
                  rotate: 360,
                  scale: 6,
                  x: 0,
                  opacity: 1,
                }}
                dragConstraints={{ top: 25, right: 25, bottom: 25, left: 25 }}
                initial={{ rotate: 0, scale: 10, opacity: 0, x: `-100vw` }}
                sx={{
                  cursor: "grab",
                  width: "16",
                  height: "16",
                  display: ["none", null, null, "block"],
                }}
                transition={{
                  delay: 1,
                  duration: 1,
                  type: "spring",
                  stiffness: 50,
                }}
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
                drag
              >
                <motion.path
                  // eslint-disable-next-line max-len
                  d="M9.2 44.8c-.6-2.3-.8-4.6-.8-6.8.1-5.4 2-10.5 5.2-14.7 3.3-6.9 10.3-11.6 18.4-11.6 11.3 0 20.4 9.1 20.4 20.4 0 3.1-.7 6.1-2 8.7 3.2-4.2 5.1-9.3 5.2-14.7.1-2.2-.2-4.5-.8-6.8C51.4 6.1 38.5-1.9 25.4.7c-.4.1-.9.2-1.3.3s-.8.2-1.3.4C6.5 6.2-3.3 23.2 1 39.9s20.9 27 37.6 23.4c-13 2.6-26-5.4-29.4-18.5z"
                  fill="#5596cb"
                />
                <motion.path
                  // eslint-disable-next-line max-len
                  d="M63 24.1C58.8 7.4 42.1-2.8 25.4.7c13.1-2.6 26.1 5.4 29.4 18.5.6 2.3.8 4.6.8 6.8-.1 5.4-2 10.5-5.2 14.7-3.3 6.9-10.3 11.7-18.4 11.7-11.3 0-20.4-9.1-20.4-20.4 0-3.1.7-6.1 2-8.7-3.2 4.2-5 9.3-5.2 14.7-.1 2.2.2 4.5.8 6.8 3.3 13.1 16.3 21.1 29.4 18.5.4-.1.8-.2 1.3-.3.4-.1.8-.2 1.3-.4C57.5 57.8 67.3 40.8 63 24.1z"
                  fill="#3b4c7a"
                />
                <motion.path
                  // eslint-disable-next-line max-len
                  d="M32 11.6c-8.1 0-15.1 4.8-18.4 11.6-1.3 2.6-2 5.6-2 8.7 0 11.3 9.1 20.4 20.4 20.4 8.1 0 15.1-4.8 18.4-11.6 1.3-2.7 2-5.6 2-8.7 0-11.3-9.1-20.4-20.4-20.4z"
                  fill="#dd242f"
                />
                <motion.path
                  animate={{ opacity: 1, scale: 1, rotate: 720 }}
                  d="M27 23.2c-1.1-.6-2.5.2-2.5 1.4v14.5c0 1.3 1.4 2.1 2.5 1.4l12.9-7.2c1.1-.6 1.1-2.3 0-2.9L27 23.2z"
                  fill="#fff"
                  initial={{ opacity: 0, scale: 0, rotate: 0 }}
                  transition={{
                    duration: 2,
                    delay: 1,
                    ease: "easeOut",
                  }}
                />
              </motion.svg>
            </Flex>
          </Flex>
        </Container>
      </Flex>
    </Box>
  );
};

Hero.propTypes = {
  id: PropTypes.string.isRequired,
  pathname: PropTypes.string,
};

Hero.defaultProps = {
  pathname: "/",
};

export default Hero;
