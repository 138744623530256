/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import MainLayout from "../templates/MainLayout";
import Hero from "../components/Hero";
import SolutionsOverview from "../components/SolutionsOverview";
import StudiesOverview from "../components/StudiesOverview";
import ServicesOverview from "../components/ServicesOverview";
import ContactSection from "../components/ContactSection";
import WhyOmneSection from "../components/WhyOmneSection";

const IndexPage = ({ location: { pathname } }) => (
  <MainLayout title="Let's Build Your Next Success">
    <Hero id="top" pathname={pathname} />
    <ServicesOverview id="solutions" />
    <SolutionsOverview />
    <StudiesOverview id="work" />
    <WhyOmneSection id="why-omne" />
    <ContactSection id="contact" />
  </MainLayout>
);

IndexPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

IndexPage.defaultProps = {
  location: {
    pathname: "/",
  },
};

export default IndexPage;
