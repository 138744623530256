/* eslint-disable max-len */
/** @jsx jsx */
import { Container, Heading, jsx, Text } from "theme-ui";
import PropTypes from "prop-types";
import { Stack } from "raam";

const ServicesOverview = ({ id }) => (
  <Container id={id} px="3" py="8" sx={{ position: "relative", zIndex: "1" }}>
    <Stack gap="6">
      <Stack gap="4" sx={{ textAlign: "center" }}>
        <Heading as="h3" variant="heading.two">
          Modern problems need modern solutions.
        </Heading>
        <Text mx="auto" variant="subhead">
          Concept to execution, design to deployment and tracking: we’ve got you
          covered.
        </Text>
      </Stack>
    </Stack>
  </Container>
);

ServicesOverview.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ServicesOverview;
