/** @jsx jsx */
import { Box, Heading, Button, Flex, Grid, jsx } from "theme-ui";
import PropTypes from "prop-types";
import { Stack } from "raam";
import BackgroundImage from "gatsby-background-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  // faEnvelope,
  faArrowRight,
} from "@fortawesome/pro-solid-svg-icons";
import useSiteMetadata from "../../hooks/use-site-metadata";
import { toTitleCase, toCamelCase, toHyphenatedCase } from "../../hooks/utils";

const LocationCard = ({ name, image }) => {
  const {
    siteMetadata: { contactNumbers },
  } = useSiteMetadata();

  return (
    <Stack key={name} gap="3">
      <BackgroundImage
        alt={name}
        fluid={image}
        sx={{
          bg: "blue.xdark",
          position: "relative",
        }}
      >
        <Box
          bg="blue.xdark"
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            opacity: "0.35",
            zIndex: "-1",
          }}
        />
        <Grid color="white" sx={{ height: "56" }} variant="placeCenter">
          <Heading
            as="h5"
            sx={{ textShadow: "text", textTransform: "uppercase" }}
            variant="heading.two"
          >
            {toHyphenatedCase(name)}
          </Heading>
        </Grid>
      </BackgroundImage>
      <Flex
        sx={{
          justifyContent: "center",
          flexDirection: ["column", null, "row"],
        }}
      >
        <Button
          as="a"
          href={`https://grahammedia.my.site.com/defaulthelpcenter29Dec/s/${toCamelCase(
            toTitleCase(name)
          ).toLowerCase()}-request-consult`}
          sx={{ ml: ["0", null, null, "0"] }}
          target="_blank"
          variant="white"
        >
          <FontAwesomeIcon icon={faArrowRight} sx={{ mr: "2" }} />
          {`${toTitleCase(name)} - Schedule a One Hour Free Consultation`}
        </Button>

        <Button
          as="a"
          href={contactNumbers[toCamelCase(name)].url}
          variant="white"
        >
          <FontAwesomeIcon icon={faPhone} sx={{ mr: "2" }} />
          {contactNumbers[toCamelCase(name)].phone}
        </Button>
      </Flex>
    </Stack>
  );
};

LocationCard.propTypes = {
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
    base64: PropTypes.string,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebP: PropTypes.string,
    srcWebP: PropTypes.string,
  }),
  name: PropTypes.string,
};

LocationCard.defaultProps = {
  image: {},
  name: "",
};

export default LocationCard;
