/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx, Box, Container, Heading, Grid } from "theme-ui";
import { useStaticQuery, graphql } from "gatsby";
import { Stack } from "raam";
import LocationCard from "./Cards/LocationCard";

const ContactSection = ({ id }) => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query LocationsQuery {
      allFile(
        sort: { order: ASC, fields: name }
        filter: { relativeDirectory: { eq: "locations" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            name
          }
        }
      }
    }
  `);

  return (
    <Box backgroundColor="white" id={id} py="7" sx={{ textAlign: "center" }}>
      <Stack gap="7">
        <Container>
          <Stack
            gap="3"
            px={["3", null, null, null, "0"]}
            sx={{ width: "10/12", mx: "auto" }}
          >
            <Heading color="blue.xdark" variant="heading">
              Let’s Talk
            </Heading>
            <Heading variant="subhead">
              We appreciate your interest and respect your time. Send an email
              to any of our offices and expect a response within a business day.
            </Heading>
          </Stack>
        </Container>
        <Grid columns={["1fr", null, "1fr 1fr"]} gap="3" sx={{ rowGap: "7" }}>
          {edges.map((station) => (
            <LocationCard
              key={station.node.name}
              image={station.node.childImageSharp.fluid}
              name={station.node.name}
            />
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

ContactSection.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ContactSection;
