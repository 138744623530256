/* eslint-disable max-len */
/** @jsx jsx */
import { useContext } from "react";
import { jsx, useThemeUI } from "theme-ui";
import { motion } from "framer-motion";
import { IntersectionContext } from "../../hooks/intersection-observer";

const OmneSocialAnim = () => {
  const { theme } = useThemeUI();
  const { inView } = useContext(IntersectionContext);

  const wrapper = {
    initial: {
      opacity: 0,
      x: "-100%",
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        type: "spring",
        stiffness: 50,
      },
    },
  };

  const pageContent = {
    initial: {},
    animate: {
      transition: {
        type: "spring",
        stiffness: 50,
        when: "beforeChildren",
        staggerChildren: 0.05,
      },
    },
  };

  const pageItem = {
    initial: {
      opacity: 0,
      y: -8,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.1,
        type: "spring",
        stiffness: "50",
      },
    },
  };

  const logoContainerAnim = {
    initial: {
      scale: 0,
    },
    animate: {
      scale: 1.35,
      y: 64,
      transition: {
        delay: 1,
        duration: 2,
        type: "spring",
        stiffness: 50,
        when: "beforeChildren",
        staggerChildren: 1,
      },
    },
  };

  const logoAnim = {
    initial: {
      scale: 0,
    },
    animate: {
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 50,
      },
    },
  };

  return (
    <motion.svg
      animate={inView ? "animate" : "initial"}
      fill="none"
      initial="initial"
      sx={{
        width: "full",
        height: "full",
        transformOrigin: "center center !important",
      }}
      variants={wrapper}
      viewBox="0 0 680 769"
    >
      <g>
        <path
          d="M521.83 171.26H515.64C514.375 171.26 513.35 172.285 513.35 173.55V257.79C513.35 259.055 514.375 260.08 515.64 260.08H521.83C523.095 260.08 524.12 259.055 524.12 257.79V173.55C524.12 172.285 523.095 171.26 521.83 171.26Z"
          fill={theme.colors.grey.light}
        />
        <path
          d="M158.98 101.68H157.51C156.245 101.68 155.22 102.705 155.22 103.97V128.63C155.22 129.895 156.245 130.92 157.51 130.92H158.98C160.245 130.92 161.27 129.895 161.27 128.63V103.97C161.27 102.705 160.245 101.68 158.98 101.68Z"
          fill={theme.colors.grey.light}
        />
        <path
          d="M159.53 155.21H157.29C156.025 155.21 155 156.235 155 157.5V203.81C155 205.075 156.025 206.1 157.29 206.1H159.53C160.795 206.1 161.82 205.075 161.82 203.81V157.5C161.82 156.235 160.795 155.21 159.53 155.21Z"
          fill={theme.colors.grey.light}
        />
        <path
          d="M159.31 223.91H157.4C156.135 223.91 155.11 224.935 155.11 226.2V272.95C155.11 274.215 156.135 275.24 157.4 275.24H159.31C160.575 275.24 161.6 274.215 161.6 272.95V226.2C161.6 224.935 160.575 223.91 159.31 223.91Z"
          fill={theme.colors.grey.light}
        />
        <path
          d="M480.17 5.05005H197.63C176.096 5.05005 158.64 22.5065 158.64 44.0401V698.77C158.64 720.304 176.096 737.76 197.63 737.76H480.17C501.704 737.76 519.16 720.304 519.16 698.77V44.0401C519.16 22.5065 501.704 5.05005 480.17 5.05005Z"
          fill={theme.colors.blue.xdark}
        />
        <path
          d="M350.61 26.9301H308C305.863 26.9301 304.13 28.6627 304.13 30.8001V33.2801C304.13 35.4174 305.863 37.1501 308 37.1501H350.61C352.747 37.1501 354.48 35.4174 354.48 33.2801V30.8001C354.48 28.6627 352.747 26.9301 350.61 26.9301Z"
          fill={theme.colors.grey.light}
        />
        <path
          d="M367.88 37.84C371.083 37.84 373.68 35.2432 373.68 32.04C373.68 28.8367 371.083 26.24 367.88 26.24C364.677 26.24 362.08 28.8367 362.08 32.04C362.08 35.2432 364.677 37.84 367.88 37.84Z"
          fill={theme.colors.grey.light}
        />
      </g>
      <path
        d="M468.27 24.14H427V29.14C426.997 35.3921 424.512 41.3872 420.09 45.8071C415.668 50.2271 409.672 52.71 403.42 52.71H271.84C265.588 52.71 259.592 50.2271 255.17 45.8071C250.748 41.3872 248.263 35.3921 248.26 29.14V24.14H209.54C201.947 24.14 194.665 27.1564 189.296 32.5256C183.926 37.8947 180.91 45.1769 180.91 52.77V690.05C180.91 697.643 183.926 704.925 189.296 710.294C194.665 715.664 201.947 718.68 209.54 718.68H468.27C475.863 718.68 483.145 715.664 488.514 710.294C493.884 704.925 496.9 697.643 496.9 690.05V52.77C496.9 45.1769 493.884 37.8947 488.514 32.5256C483.145 27.1564 475.863 24.14 468.27 24.14V24.14Z"
        fill={theme.colors.white}
      />
      <mask
        height="695"
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        width="317"
        x="180"
        y="24"
      >
        <path
          d="M468.27 24.14H427V29.14C426.997 35.3921 424.512 41.3872 420.09 45.8071C415.668 50.2271 409.672 52.71 403.42 52.71H271.84C265.588 52.71 259.592 50.2271 255.17 45.8071C250.748 41.3872 248.263 35.3921 248.26 29.14V24.14H209.54C201.947 24.14 194.665 27.1564 189.296 32.5256C183.926 37.8947 180.91 45.1769 180.91 52.77V690.05C180.91 697.643 183.926 704.925 189.296 710.294C194.665 715.664 201.947 718.68 209.54 718.68H468.27C475.863 718.68 483.145 715.664 488.514 710.294C493.884 704.925 496.9 697.643 496.9 690.05V52.77C496.9 45.1769 493.884 37.8947 488.514 32.5256C483.145 27.1564 475.863 24.14 468.27 24.14V24.14Z"
          fill="#ECECF3"
        />
      </mask>
      <g mask="url(#mask0)">
        <path d="M180 23H497V102H180V23Z" fill="#327FE3" />
        <circle cx="223" cy="66" fill="#86B3EE" r="20" />
        <rect fill="#86B3EE" height="7" width="40" x="437" y="46" />
        <rect fill="#86B3EE" height="8" width="40" x="437" y="62" />
        <rect fill="#86B3EE" height="7" width="40" x="437" y="79" />
      </g>
      <motion.g
        animate={inView ? "animate" : "initial"}
        initial="initial"
        variants={pageContent}
      >
        <motion.path
          d="M269 137H219V187H269V137Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M391.815 137H285V148.11H391.815V137Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 156.445H285V167.555H461V156.445Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 175.89H285V187H461V175.89Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M269 219H219V269H269V219Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M391.815 219H285V230.11H391.815V219Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 238.445H285V249.555H461V238.445Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 257.89H285V269H461V257.89Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M269 301H219V351H269V301Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M391.815 301H285V312.11H391.815V301Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 320.445H285V331.555H461V320.445Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 339.89H285V351H461V339.89Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M269 383H219V433H269V383Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M391.815 383H285V394.11H391.815V383Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 402.445H285V413.555H461V402.445Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 421.89H285V433H461V421.89Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M269 465H219V515H269V465Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M391.815 465H285V476.11H391.815V465Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 484.445H285V495.555H461V484.445Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 503.89H285V515H461V503.89Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M269 547H219V597H269V547Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M391.815 547H285V558.11H391.815V547Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 566.445H285V577.555H461V566.445Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 585.89H285V597H461V585.89Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M269 629H219V679H269V629Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M391.815 629H285V640.11H391.815V629Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 648.445H285V659.555H461V648.445Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
        <motion.path
          d="M461 667.89H285V679H461V667.89Z"
          fill={theme.colors.grey.light}
          variants={pageItem}
        />
      </motion.g>
      <motion.g
        animate={inView ? "animate" : "initial"}
        initial="initial"
        sx={{
          transformOrigin: "center center !important",
        }}
        variants={logoContainerAnim}
      >
        <motion.path
          animate={inView ? "animate" : "initial"}
          d="M550 290H130C121.163 290 114 297.163 114 306V346C114 354.837 121.163 362 130 362H550C558.837 362 566 354.837 566 346V306C566 297.163 558.837 290 550 290Z"
          fill="white"
          initial="initial"
          variants={logoAnim}
        />
        <motion.g
          animate={inView ? "animate" : "initial"}
          clipPath="url(#clip0)"
          initial="initial"
          variants={logoAnim}
        >
          <path
            d="M469.54 322.417C473.509 322.417 476.724 319.22 476.724 315.272C476.724 311.324 473.509 308.126 469.54 308.126C465.572 308.126 462.357 311.324 462.357 315.272C462.357 319.22 465.572 322.417 469.54 322.417ZM523.377 325.99H516.193C514.288 325.99 512.482 326.74 511.133 328.063C515.737 330.55 518.872 335.09 519.567 340.262H526.968C528.953 340.262 530.56 338.663 530.56 336.689V333.116C530.56 329.188 527.345 326.01 523.377 325.99ZM465.949 325.99C461.98 325.99 458.765 329.188 458.765 333.136V336.709C458.765 338.683 460.373 340.282 462.357 340.282H469.759C470.453 335.11 473.589 330.57 478.192 328.083C476.843 326.76 475.037 326.01 473.132 326.01H465.949V325.99ZM519.785 322.417C523.754 322.417 526.968 319.22 526.968 315.272C526.968 311.324 523.754 308.126 519.785 308.126C515.816 308.126 512.601 311.324 512.601 315.272C512.621 319.22 515.836 322.417 519.785 322.417Z"
            fill="#5596CB"
          />
          <path
            d="M503.275 329.563H502.342C499.941 330.728 497.322 331.34 494.663 331.34C491.904 331.34 489.325 330.668 486.983 329.563H486.05C478.907 329.563 473.132 335.327 473.132 342.413V345.631C473.132 348.592 475.533 350.98 478.51 350.98H510.815C513.792 350.98 516.193 348.592 516.193 345.631V342.413C516.193 335.327 510.419 329.563 503.275 329.563ZM494.663 325.99C501.608 325.99 507.224 320.404 507.224 313.495C507.224 306.586 501.608 301 494.663 301C487.717 301 482.102 306.586 482.102 313.495C482.102 320.404 487.717 325.99 494.663 325.99C494.663 325.99 494.643 325.99 494.663 325.99Z"
            fill="#3B4C7A"
          />
          <path
            d="M318.668 346.085C309.957 346.085 304.242 341.051 304.242 333.531H309.024C309.024 338.564 312.953 341.703 318.668 341.703C325.138 341.703 328.313 338.998 328.313 334.774C328.313 331.636 326.904 329.464 318.569 328.063C310.195 326.602 305.393 323.997 305.393 317.561C305.393 311.126 310.572 306.942 318.569 306.942C326.844 306.942 331.964 311.383 331.964 318.647H327.281C327.281 314.265 324.284 311.344 318.629 311.344C312.815 311.344 310.076 313.89 310.076 317.404C310.076 320.661 311.822 322.279 319.542 323.681C327.816 325.26 332.936 327.569 332.936 334.774C332.916 342.137 327.102 346.085 318.668 346.085Z"
            fill="#3B4C7A"
          />
          <path
            d="M363.595 331.576C363.595 339.59 357.503 346.085 349.506 346.085C341.568 346.085 335.417 339.59 335.417 331.576C335.417 323.463 341.568 316.851 349.506 316.851C357.503 316.851 363.595 323.444 363.595 331.576ZM358.971 331.576C358.971 325.773 354.725 321.075 349.506 321.075C344.326 321.075 340.08 325.793 340.08 331.576C340.08 337.261 344.326 341.86 349.506 341.86C354.725 341.86 358.971 337.261 358.971 331.576Z"
            fill="#3B4C7A"
          />
          <path
            d="M379.748 346.085C372.008 346.085 365.698 339.59 365.698 331.517C365.698 323.444 372.008 316.851 379.748 316.851C386.018 316.851 390.9 320.542 392.805 326.168H387.844C386.256 323.128 383.379 321.075 379.728 321.075C374.548 321.075 370.302 325.793 370.302 331.517C370.302 337.261 374.548 341.86 379.728 341.86C383.438 341.86 386.316 339.808 387.844 336.768H392.805C390.959 342.413 386.018 346.085 379.748 346.085Z"
            fill="#3B4C7A"
          />
          <path
            d="M401.08 313.317H396.456V307.85H401.08V313.317ZM401.08 345.434H396.456V317.542H401.08V345.434Z"
            fill="#3B4C7A"
          />
          <path
            d="M425.606 342.236C423.602 344.664 420.328 346.085 415.962 346.085C410.188 346.085 405.028 343.479 405.028 337.479C405.028 331.734 409.215 329.148 416.458 328.754C423.324 328.438 425.547 327.175 425.547 324.964C425.547 322.753 423.424 321.055 418.681 321.055C415.744 321.055 411.875 321.983 411.378 326.03H406.596C406.914 320.443 411.557 316.831 418.74 316.831C426.261 316.831 430.071 320.305 430.071 325.497V340.874C430.071 342.275 430.448 344.348 430.885 345.414H426.321C425.944 344.624 425.666 343.539 425.606 342.236ZM425.567 330.432C423.721 332.05 420.387 332.425 417.074 332.642C410.982 333.017 409.612 335.13 409.612 337.459C409.612 340.499 412.549 341.782 416.141 341.782C420.923 341.782 425.448 339.729 425.567 334.695V330.432V330.432Z"
            fill="#3B4C7A"
          />
          <path
            d="M440.39 345.433H435.766V305.145H440.39V345.433Z"
            fill="#3B4C7A"
          />
          <path
            d="M187.005 326.484C187.005 337.419 178.631 346.085 168.273 346.085C157.914 346.085 149.56 337.479 149.56 326.484C149.56 315.548 157.934 306.942 168.292 306.942C178.79 306.942 187.005 315.331 187.005 326.484ZM182.441 326.484C182.441 317.818 176.23 311.324 168.292 311.324C160.494 311.324 154.124 317.976 154.124 326.484C154.124 335.031 160.494 341.644 168.273 341.644C176.051 341.644 182.441 334.991 182.441 326.484Z"
            fill="#DD242F"
          />
          <path
            d="M231.257 345.434H226.633V328.043C226.633 323.72 224.569 321.055 220.263 321.055C216.453 321.055 213.675 324.194 213.675 328.359V345.414H209.111V328.023C209.111 323.7 206.928 321.036 202.801 321.036C198.991 321.036 196.153 324.174 196.153 328.339V345.394H191.53V317.542H195.994V320.582C197.741 318.252 200.34 316.851 203.456 316.851C207.424 316.851 210.361 318.647 212.108 321.608C213.854 318.746 216.85 316.851 220.878 316.851C227.348 316.851 231.277 321.233 231.277 328.162V345.434H231.257Z"
            fill="#DD242F"
          />
          <path
            d="M260.288 345.434H255.724V328.477C255.724 324.194 253.323 321.055 248.858 321.055C244.612 321.055 241.516 324.194 241.516 328.359V345.414H236.892V317.542H241.357V320.582C243.262 318.252 246.14 316.851 249.513 316.851C256.379 316.851 260.288 321.726 260.288 328.655V345.434Z"
            fill="#DD242F"
          />
          <path
            d="M291.522 336.768C289.558 342.729 284.775 346.085 278.286 346.085C270.23 346.085 263.92 339.689 263.92 331.517C263.92 323.444 270.23 316.851 278.009 316.851C285.252 316.851 292.217 322.812 292.157 332.721H268.623C269.218 338.525 273.683 341.92 278.306 341.92C282.275 341.92 285.113 340.025 286.641 336.768H291.522ZM287.077 328.596C285.926 324.056 282.116 321.016 278.048 321.016C274.179 321.016 270.111 323.839 268.9 328.596H287.077Z"
            fill="#DD242F"
          />
        </motion.g>
      </motion.g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="50" width="381" x="149.56" y="301" />
        </clipPath>
      </defs>
    </motion.svg>
  );
};

export default OmneSocialAnim;
