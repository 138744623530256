/** @jsx jsx */
import { Box, jsx, Heading, Container, Text, Grid } from "theme-ui";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Stack } from "raam";

const WhyOmneSection = ({ id }) => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query WhyOmneQuery {
      allFile(
        sort: { order: ASC, fields: name }
        filter: { relativeDirectory: { eq: "whyOmne" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 632) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            name
          }
        }
      }
    }
  `);

  return (
    <Box bg="blue.xlight" id={id} px="3" py="7">
      <Container>
        <Stack gap="7">
          <Heading
            color="blue.xdark"
            sx={{ textAlign: "center" }}
            variant="heading.two"
          >
            For 71 years, we have connected communities with companies to
            strengthen our local economies.
          </Heading>
          <Grid columns={["1fr", null, "repeat(2, 1fr)"]}>
            <Text variant="body.mid">
              Omne is built on the foundation of Graham Media Group and the tech
              hub of Graham Digital, a leading digital innovation company. While
              the medium has changed our mission has not.
            </Text>
            <Text variant="body.mid">
              This mission has earned awards, accolades and collaborations with
              Google, Facebook YouTube, Amazon and many others. Graham Digital
              is widely recognized as a leading industry innovator.
            </Text>
          </Grid>
          <Grid columns={["1fr", null, "repeat(2, 1fr)"]}>
            <Grid columns="repeat(2, 1fr)">
              {edges.slice(0, 2).map((item) => (
                <Img
                  key={item.node.name}
                  bg="blue.mid"
                  fluid={item.node.childImageSharp.fluid}
                />
              ))}
            </Grid>
            {edges.slice(2, 4).map((item) => (
              <Img
                key={item.node.name}
                bg="blue.mid"
                fluid={item.node.childImageSharp.fluid}
              />
            ))}
            <Grid columns="repeat(2, 1fr)">
              {edges.slice(4, 6).map((item) => (
                <Img
                  key={item.node.name}
                  bg="blue.mid"
                  fluid={item.node.childImageSharp.fluid}
                />
              ))}
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
};

WhyOmneSection.propTypes = {
  id: PropTypes.string.isRequired,
};

export default WhyOmneSection;
